import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect } from 'react';
import { Collapse, Nav } from 'react-bootstrap';
import FeatherIcon from 'feather-icons-react';
import { Route } from 'sitemap';
import { capitalize } from 'helpers/utils';
import classNames from 'classnames';
import { NavLink, useLocation } from 'react-router-dom';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faCaretRight } from '@fortawesome/free-solid-svg-icons';
import Badge from 'components/base/Badge';
import { useAppContext } from 'providers/AppProvider';
import { useNavbarVerticalCollapse } from './navbar-collapse-provider';
import { SearchParamsKey } from 'enums';
import { useGlobalStore } from 'global-store';
import { getQueryConcatenator } from 'helpers';
import {
  useOrdersFilterStore,
  useOrdersPaginationStore,
  useOrdersSearchStore
} from 'modules/orders';
import {
  usePaymentsHistoryPaginationStore,
  usePaymentsHistorySearchStore
} from 'modules/payments-history';
import useTransactionsSearchStore from 'modules/transactions/transactions-search-filter/store/store';
import useTransactionsPaginationStore from 'modules/transactions/transactions-footer/store/store';

interface NavbarVerticalMenuProps {
  routes: Route[];
  level: number;
}

interface NavItemProps {
  route: Route;
  level: number;
}

const NavItem = ({ route, level }: NavItemProps) => {
  const {
    config: { isNavbarVerticalCollapsed }
  } = useAppContext();
  const ordersDate = useGlobalStore.use.ordersDate();
  const paymentsDate = useGlobalStore.use.paymentsDate();

  const ordersStatus = useOrdersFilterStore.use.status();
  const ordersPageIndex = useOrdersPaginationStore.use.pageIndex();
  const ordersSearch = useOrdersSearchStore.use.search();

  const paymentsHistoryPageIndex =
    usePaymentsHistoryPaginationStore.use.pageIndex();
  const paymentsSearch = usePaymentsHistorySearchStore.use.search();

  const transactionsDate = useGlobalStore.getState().transactionsDate;
  const transactionsSearch = useTransactionsSearchStore.getState().search;
  const transactionsPageIndex =
    useTransactionsPaginationStore.getState().pageIndex;

  const getOrdersQueryParams = () => {
    const concat = getQueryConcatenator();
    if (ordersPageIndex !== 0) {
      concat.set(SearchParamsKey.PageIndex, ordersPageIndex.toString());
    }
    if (ordersDate.startDate !== null && ordersDate.endDate !== null) {
      concat
        .set(SearchParamsKey.StartDate, ordersDate.startDate)
        .set(SearchParamsKey.EndDate, ordersDate.endDate);
    }
    if (ordersStatus.length > 0) {
      concat.setArray(SearchParamsKey.PaymentStatus, ordersStatus);
    }
    if (ordersSearch.length > 0) {
      concat.set(SearchParamsKey.Search, ordersSearch);
    }

    return concat.toString();
  };

  const getPaymentsHistoryQueryParams = () => {
    const concat = getQueryConcatenator();
    if (paymentsHistoryPageIndex !== 0) {
      concat.set(
        SearchParamsKey.PageIndex,
        paymentsHistoryPageIndex.toString()
      );
    }
    if (paymentsDate.startDate !== null && paymentsDate.endDate !== null) {
      concat
        .set(SearchParamsKey.StartDate, paymentsDate.startDate)
        .set(SearchParamsKey.EndDate, paymentsDate.endDate);
    }
    if (paymentsSearch.length > 0) {
      concat.set(SearchParamsKey.Search, paymentsSearch);
    }

    return concat.toString();
  };

  const getTransactionsQueryParams = () => {
    const concat = getQueryConcatenator();
    if (transactionsPageIndex !== 0) {
      concat.set(SearchParamsKey.PageIndex, transactionsPageIndex.toString());
    }
    if (
      transactionsDate.startDate !== null &&
      transactionsDate.endDate !== null
    ) {
      concat
        .set(SearchParamsKey.StartDate, transactionsDate.startDate)
        .set(SearchParamsKey.EndDate, transactionsDate.endDate);
    }
    if (transactionsSearch.length > 0) {
      concat.set(SearchParamsKey.Search, transactionsSearch);
    }

    return concat.toString();
  };

  const { setOpenItems, openItems } = useNavbarVerticalCollapse();
  return (
    <Nav.Item as="li">
      <NavLink
        to={{
          pathname: route.path ? route.path : '#!',
          search:
            route.name === 'Заказы'
              ? getOrdersQueryParams()
              : route.name === 'История выплат'
              ? getPaymentsHistoryQueryParams()
              : route.name === 'Транзакции'
              ? getTransactionsQueryParams()
              : ''
        }}
        className={({ isActive }) =>
          classNames('nav-link', {
            'label-1': level === 1,
            active: isActive && route.path !== '#!'
          })
        }
        onClick={() => level === 1 && setOpenItems(openItems.map(() => ''))}
      >
        <div
          className={classNames('d-flex align-items-center', {
            'text-light': !route.active
          })}
        >
          {route.icon ? (
            <>
              <span
                className={classNames('nav-link-icon', {
                  new: route.new || route.hasNew
                })}
              >
                {route.iconSet === 'font-awesome' ? (
                  <FontAwesomeIcon
                    icon={route.icon as IconProp}
                    transform={{ size: 16 }}
                  />
                ) : (
                  <FeatherIcon icon={route.icon} size={16} />
                )}
              </span>
              <span className="nav-link-text-wrapper">
                <span className="nav-link-text">{capitalize(route.name)}</span>
                {route.new && !isNavbarVerticalCollapsed && (
                  <Badge variant="phoenix" bg="info" className="ms-2">
                    New
                  </Badge>
                )}
              </span>
            </>
          ) : (
            <>
              <span className="nav-link-text">{capitalize(route.name)}</span>
              {route.new && (
                <Badge variant="phoenix" bg="info" className="ms-2">
                  New
                </Badge>
              )}
            </>
          )}
        </div>
      </NavLink>
    </Nav.Item>
  );
};

const CollapsableNavItem = ({ route, level }: NavItemProps) => {
  const { pathname } = useLocation();
  const { setOpenItems, openItems } = useNavbarVerticalCollapse();
  const {
    config: { isNavbarVerticalCollapsed }
  } = useAppContext();

  const openCollapse = (childrens: Route[] = []) => {
    const checkLink = (children: Route) => {
      if (`${children.path}` === pathname) {
        return true;
      }
      return children.pages && children.pages.some(checkLink);
    };
    return childrens.some(checkLink);
  };

  const updateOpenItems = (name: string) => {
    const updatedOpenItems = [...openItems];
    updatedOpenItems[level] = name;
    updatedOpenItems.forEach((item, index) => {
      if (index > level) {
        updatedOpenItems[index] = '';
      }
    });
    setOpenItems(updatedOpenItems);
  };

  useEffect(() => {
    if (openCollapse(route.pages)) {
      updateOpenItems(route.name);
    }
  }, []);

  return (
    <>
      <Nav.Link
        onClick={() => {
          if (route.disabled) {
            return;
          }
          if (route.name === openItems[level]) {
            updateOpenItems('');
          } else {
            updateOpenItems(route.name);
          }
        }}
        className={classNames('dropdown-indicator', {
          'label-1': level === 1,
          collapsed: openItems[level] !== route.name,
          'text-light': !route.active
        })}
        aria-expanded={openItems[level] === route.name}
      >
        <div className="d-flex align-items-center">
          <div className="dropdown-indicator-icon">
            <FontAwesomeIcon
              icon={faCaretRight}
              className={classNames({
                'text-light': !route.active
              })}
            />
          </div>
          {level === 1 && (
            <span
              className={classNames('nav-link-icon', {
                new: route.new || route.hasNew
              })}
            >
              <FeatherIcon icon={route.icon} size={16} />
            </span>
          )}
          <span
            className={classNames('nav-link-text', {
              new: route.hasNew
            })}
          >
            {capitalize(route.name)}
            {(!isNavbarVerticalCollapsed || level !== 1) && route.new && (
              <Badge variant="phoenix" bg="info" className="ms-2">
                New
              </Badge>
            )}
          </span>
        </div>
      </Nav.Link>
      <div
        className={classNames('parent-wrapper', {
          'label-1': level === 1
        })}
      >
        <Collapse in={openItems[level] === route.name} className="nav parent">
          <div>
            {level === 1 && (
              <div className="collapsed-nav-item-title d-none">
                {capitalize(route.name)}
                {isNavbarVerticalCollapsed && route.new && (
                  <Badge variant="phoenix" bg="info" className="ms-2">
                    New
                  </Badge>
                )}
              </div>
            )}
            <NavbarVerticalMenu routes={route.pages || []} level={level + 1} />
          </div>
        </Collapse>
      </div>
    </>
  );
};

const NavbarVerticalMenu = ({ routes, level }: NavbarVerticalMenuProps) => {
  return (
    <>
      {routes.map(route => (
        <div key={route.name}>
          {level === 1 ? (
            <div className="nav-item-wrapper">
              {route.pages ? (
                <CollapsableNavItem route={route} level={level} />
              ) : (
                <NavItem route={route} level={level} />
              )}
            </div>
          ) : (
            <>
              {route.pages ? (
                <CollapsableNavItem route={route} level={level} />
              ) : (
                <NavItem route={route} level={level} />
              )}
            </>
          )}
        </div>
      ))}
    </>
  );
};

export default NavbarVerticalMenu;
