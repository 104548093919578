import { useUserStore } from 'modules/authorization';
import { Navigate } from 'react-router-dom';

// eslint-disable-next-line react/prop-types
const PrivateRoute: React.FC<{ element: JSX.Element }> = ({ element }) => {
  const isUserValid = useUserStore(state => state.isUserValid);

  return isUserValid ? element : <Navigate to="/auth/sign-in" />;
};

export default PrivateRoute;
