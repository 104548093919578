import { ResetPasswordCardLayout, RootLayout } from 'layouts';
import App from './App';
import {
  HomePage,
  InstallmentPage,
  OrdersPage,
  PaymentHistoryPage,
  installmentPageLoader,
  ordersPageLoader,
  paymentsHistoryLoader,
  homePageLoader,
  ForgotPasswordPage,
  TwoFaFormPage,
  ResetPasswordPage,
  // signInPageAction,
  // authLoader,
  // signInPageLoader,
  forgotPasswordPageLoader,
  thoFaPageLoader,
  resetPasswordPageLoader,
  resetLoader
} from 'pages';
import { loader as balanceAndTransactionsLoader } from './pages/transactions/loader';
import AuthLayout from 'layouts/auth-layout/layout';
// import SignInPage from 'pages/authentication/sign-in/page';
import { TransactionsPage } from 'pages/transactions/page';
import {
  createBrowserRouter,
  RouteObject,
  useRouteError
} from 'react-router-dom';
import OAuthLogin from './modules/authorization/OAuth/OAuthLogin';
import Callback from './modules/authorization/OAuth/Callback';
import PrivateRoute from 'pages/private/PrivateRoute';

const routes: RouteObject[] = [
  {
    element: <App />,
    errorElement: <ErrorBoundary />,
    children: [
      {
        path: '/',
        element: <PrivateRoute element={<RootLayout />} />,
        errorElement: <ErrorBoundary />,
        children: [
          {
            index: true,
            loader: homePageLoader,
            element: <PrivateRoute element={<HomePage />} />,
            errorElement: <ErrorBoundary />
          },
          {
            path: 'installment',
            loader: installmentPageLoader,
            element: <PrivateRoute element={<InstallmentPage />} />,
            errorElement: <ErrorBoundary />,
            children: [
              {
                path: 'orders',
                loader: ordersPageLoader,
                element: <PrivateRoute element={<OrdersPage />} />,
                errorElement: <ErrorBoundary />
              },
              // {
              //   path: 'event-log',
              //   loader: eventsLogPageLoader,
              //   errorElement: <div>Something gone wrong...</div>,
              //   element: <EventLogPage />
              // },
              {
                path: 'payment-history',
                loader: paymentsHistoryLoader,
                element: <PaymentHistoryPage />,
                errorElement: <ErrorBoundary />
              }
            ]
          },
          {
            path: 'balance-and-transactions',
            loader: balanceAndTransactionsLoader,
            errorElement: <ErrorBoundary />,
            element: <TransactionsPage />
          }
        ]
      },
      {
        path: 'auth',
        element: <AuthLayout />,
        // loader: authLoader,
        errorElement: <ErrorBoundary />,
        children: [
          {
            path: 'sign-in',
            // action: signInPageAction,
            // loader: signInPageLoader,
            // element: <SignInPage />,
            element: <OAuthLogin />,
            errorElement: <ErrorBoundary />
          }
        ]
      },
      {
        path: 'callback',
        element: <Callback />,
        errorElement: <ErrorBoundary />
      },
      {
        path: 'reset',
        element: <ResetPasswordCardLayout />,
        loader: resetLoader,
        errorElement: <ErrorBoundary />,
        children: [
          {
            path: 'forgot-password',
            loader: forgotPasswordPageLoader,
            element: <ForgotPasswordPage />,
            errorElement: <ErrorBoundary />
          },
          {
            path: '2FA',
            loader: thoFaPageLoader,
            element: <TwoFaFormPage />,
            errorElement: <ErrorBoundary />
          },
          {
            path: 'reset-password',
            loader: resetPasswordPageLoader,
            element: <ResetPasswordPage />,
            errorElement: <ErrorBoundary />
          }
        ]
      }
    ]
  }
];

function ErrorBoundary() {
  const error = useRouteError();
  if (error) {
    console.error(error);
  }
  return <div className="text-center">Something gone wrong...</div>;
}

export const router = createBrowserRouter(routes);

export default routes;
