import { createSelectors } from 'config/zustand';
import { create } from 'zustand';

type State = {
  ordersDate: {
    startDate: string | null;
    endDate: string | null;
  };
  paymentsDate: {
    startDate: string | null;
    endDate: string | null;
  };
  transactionsDate: {
    startDate: string | null;
    endDate: string | null;
  };
  statusParams: string[];
};

const initialState: State = {
  ordersDate: {
    startDate: null,
    endDate: null
  },
  paymentsDate: {
    startDate: null,
    endDate: null
  },
  transactionsDate: {
    startDate: null,
    endDate: null
  },
  statusParams: []
};

type Action = {
  setOrdersDate: (date: State['ordersDate']) => void;
  setPaymentsDate: (date: State['paymentsDate']) => void;
  setTransactionsDate: (date: State['transactionsDate']) => void;
  setStatusParams: (params: State['statusParams']) => void;
  reset: () => void;
};

const useGlobalStoreBase = create<State & Action>(set => ({
  ordersDate: initialState['ordersDate'],
  paymentsDate: initialState['paymentsDate'],
  transactionsDate: initialState['transactionsDate'],
  statusParams: initialState['statusParams'],
  setStatusParams: params => set(() => ({ statusParams: params })),
  setOrdersDate: date => set(() => ({ ordersDate: date })),
  setTransactionsDate: date => set(() => ({ transactionsDate: date })),
  setPaymentsDate: date => set(() => ({ paymentsDate: date })),
  reset: () => {
    set(initialState);
  }
}));

const useGlobalStore = createSelectors(useGlobalStoreBase);

export default useGlobalStore;
