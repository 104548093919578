import initialLogo from 'assets/img/Logo1.svg';
import classNames from 'classnames';

interface LogoProps {
  width?: number;
  text?: boolean;
  textClass?: string;
  className?: string;
  logo?: string;
}

const Logo = ({
  width = 27,
  text = true,
  textClass,
  className,
  logo = initialLogo
}: LogoProps) => {
  return (
    <div className={classNames(className, 'd-flex align-items-center')}>
      <img src={logo} alt="phoenix" width={width} />
      {text && (
        <p className={classNames(textClass, 'logo-text ms-2')}>phoenix</p>
      )}
    </div>
  );
};

export default Logo;
