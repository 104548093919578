import Button from 'components/base/Button';
import { sendCheckOrderCodeAsync } from '../../api/check-order-code';
import { ModalBase } from 'new-components';
import { useEffect, useState } from 'react';
import { Form, Spinner } from 'react-bootstrap';
import InputMask from 'react-input-mask';
import useCreateBillStore from '../store/store';

interface Props {
  show: boolean;
  onHide: () => void;
  onSuccessGenerateCodeConfirm: (code: string) => void;
  onFailGenerateCode: () => void;
  closeWSConnection: () => void;
}

const FOUR_DIGIT = '9999';
const FOUR_DIGIT_LENGTH = 4;

export const ConfirmCodeModal = ({
  show,
  onHide,
  onSuccessGenerateCodeConfirm,
  onFailGenerateCode,
  closeWSConnection
}: Props) => {
  const [code, setCode] = useState('');
  const [loading, setLoading] = useState(false);
  const [isWrongCode, setIsWrongCode] = useState(false);

  const tempBnplId = useCreateBillStore(state => state.tempBnplId);
  const setDataFromConfirmModal = useCreateBillStore(
    state => state.setDataFromConfirmModal
  );
  // console.log('tempBnplId::', tempBnplId);

  useEffect(() => {
    if (!show) {
      setCode('');
      setLoading(false);
      setIsWrongCode(false);
    }
  }, [show]);

  const submitData = () => {
    sendCheckOrderCodeAsync({ bnpl_id: tempBnplId, otp: code })
      .then(res => {
        if (res?.data.message === 'Success') {
          setDataFromConfirmModal(res.data.data);
          onSuccessGenerateCodeConfirm(code);
        } else {
          onFailGenerateCode();
          setIsWrongCode(true);
        }
      })
      .finally(() => setLoading(false));
  };

  const handleClick = async () => {
    setLoading(true);
    submitData();
  };

  const isLessFourDigit = code.length < FOUR_DIGIT_LENGTH;

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key !== 'Enter') {
      return;
    }
    if (loading) {
      return;
    }
    if (isLessFourDigit) {
      return;
    }
    setLoading(true);
    submitData();
  };

  return (
    <ModalBase
      title="Проверка кода"
      show={show}
      onHide={() => {
        onHide();
        closeWSConnection();
      }}
      titleCentered
    >
      <Form.Group className="text-center mb-2">
        <label
          style={{
            fontSize: '14px',
            fontWeight: 700,
            lineHeight: '18px',
            marginBottom: '12px',
            padding: 0
          }}
          className="modal-label"
        >
          Ввести проверочный код
        </label>
        <InputMask
          mask={FOUR_DIGIT}
          maskPlaceholder={''}
          value={code}
          onChange={e => setCode(e.target.value)}
          onKeyDown={handleKeyDown}
        >
          <Form.Control
            className="text-center"
            style={{
              maxWidth: '210px',
              fontSize: '20px',
              lineHeight: '24px',
              fontWeight: 700,
              borderColor: isWrongCode ? 'red' : undefined
            }}
          />
        </InputMask>
        {isWrongCode && (
          <Form.Label
            style={{
              color: 'red',
              fontSize: '12px',
              fontWeight: 700,
              lineHeight: '18px',
              marginTop: '12px',
              padding: 0
            }}
          >
            Неверный код
          </Form.Label>
        )}
      </Form.Group>

      <div
        className="d-flex justify-content-center align-items-center mb-2 w-100"
        style={{ height: '225px' }}
      >
        {loading && <Spinner style={{ width: '56px', height: '56px' }} />}
      </div>

      <Button
        className="w-100"
        variant="secondary"
        onClick={handleClick}
        disabled={loading || isLessFourDigit}
      >
        Подтвердить код
      </Button>
    </ModalBase>
  );
};
