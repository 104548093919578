import { DateRangePicker, RangeKeyDict } from 'react-date-range';
import './style.scss';
import { WeekDay } from 'enums';
import { ru } from 'date-fns/locale';

const DATE_DISPLAY_FORMAT = 'dd.MM.yyyy';
const TWO_LETTER = 'EEEEEE';

type RangeType = {
  startDate: Date;
  endDate: Date;
};

export interface Props {
  range: RangeType;
  setRange: (range: RangeType) => void;
}

const CustomDateRangePicker = (props: Props) => {
  const handleChange = (ranges: RangeKeyDict) => {
    props.setRange({
      startDate: ranges.selection.startDate!,
      endDate: ranges.selection.endDate!
    });
  };

  return (
    <DateRangePicker
      ranges={[{ ...props.range, key: 'selection' }]}
      onChange={handleChange}
      onPreviewChange={() => {}}
      weekStartsOn={WeekDay.Monday}
      weekdayDisplayFormat={TWO_LETTER}
      locale={ru}
      maxDate={new Date()}
      dateDisplayFormat={DATE_DISPLAY_FORMAT}
    />
  );
};

export default CustomDateRangePicker;
