import { Col, Row } from 'react-bootstrap';

const Footer = () => {
  return (
    <footer className="footer position-absolute">
      <Row className="g-1 justify-content-between align-items-center h-100">
        <Col xs={12} sm="auto" className="text-center">
          <p className="mb-0 mt-2 mt-sm-0 text-body">
            Paspay
            <span className="d-none d-sm-inline-block" />
            <span className="d-none d-sm-inline-block mx-1">|</span>
            <br className="d-sm-none" />
            {new Date().getFullYear()} &copy; Все права защищены
          </p>
        </Col>
        <Col xs={12} sm="auto" className="text-center">
          <p className="mb-0 text-body-tertiary text-opacity-85">v 1.0</p>
        </Col>
      </Row>
    </footer>
  );
};

export default Footer;
