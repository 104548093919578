import { useEffect, useState } from 'react';

export const useLocalStorageSorting = (key: string, initialValue: unknown) => {
  const [sorting, setSorting] = useState(() => {
    const storedSorting = localStorage.getItem(key);
    return storedSorting ? JSON.parse(storedSorting) : initialValue;
  });

  useEffect(() => {
    localStorage.setItem(key, JSON.stringify(sorting));
  }, [key, sorting]);

  return [sorting, setSorting] as const;
};
