import { api } from 'config/axios';

export type ReturnOrderResponseType = {
  // data: any;
  error_code: number;
  message: string;
};

export const returnOrderAsync = async (
  orderId: number,
  reason: 'client' | 'other'
) => {
  // const URL = `https://testpaspay-guard.paspay.kz/bnpl/cancel`;
  const URL = `${process.env.REACT_APP_API_TESTGUARD}/bnpl/cancel`;

  try {
    const res = await api.post(URL, {
      orderId: orderId,
      message: reason === 'client' ? 'По просьбе клиента' : 'Другая причина'
    });
    return res;
  } catch (err) {
    console.log(err);
  }
};
