import { createSelectors } from 'config/zustand';
import { create } from 'zustand';

type State = {
  search: string;
};

const initialState: State = {
  search: ''
};

type Action = {
  setSearch: (pageIndex: State['search']) => void;
  reset: () => void;
};

const useOrdersSearchBase = create<State & Action>(set => ({
  search: initialState['search'],
  setSearch: search => set(() => ({ search: search })),
  reset: () => set(initialState)
}));

const useOrdersSearchStore = createSelectors(useOrdersSearchBase);

export default useOrdersSearchStore;
