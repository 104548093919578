import { ReactComponent as Logo } from 'assets/img/Logo.svg';
import { ReactComponent as LogoLight } from 'assets/img/logoLight.svg';
import { Navbar } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useAppContext } from '../../../../providers/AppProvider';

const NavbarBrand = () => {
  const {
    config: { theme }
  } = useAppContext();

  return (
    <div className="navbar-logo">
      <Navbar.Brand as={Link} to="/" className="me-1 me-sm-3">
        <div className="d-flex align-items-center">
          {theme !== 'dark' ? <Logo /> : <LogoLight />}
          <p
            className="logo-text ms-2 d-none d-sm-block"
            style={{ fontSize: 10, fontWeight: 700 }}
          >
            ПЛАТЕЖИ
          </p>
        </div>
      </Navbar.Brand>
    </div>
  );
};

export default NavbarBrand;
