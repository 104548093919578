import { AxiosResponse } from 'axios';
import { api } from 'config/axios';
import { DataFromConfirmModal } from '../orders-qr-code/store/store';

export type VerificationCodeRequestParams = {
  code: string;
};

export type VerificationCodeResponseType = {
  errorCode: number;
  message: string;
  success: boolean;
  data: DataFromConfirmModal;
};

export const sendOrdersVerificationCodeAsync = async ({
  code
}: VerificationCodeRequestParams) => {
  // const URL = 'https://testpaspay-guard.paspay.kz/bnpl/find-by-order-code';
  const URL = `${process.env.REACT_APP_API_TESTGUARD}/bnpl/find-by-order-code`;
  try {
    const res: AxiosResponse<VerificationCodeResponseType> = await api.post(
      URL,
      { order_code: code }
    );
    return res;
  } catch (err) {
    console.log(err);
  }
};
