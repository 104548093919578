import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'components/base/Button';
import { getInstallmentQrCodeAsync } from 'modules/installment/api/installment';
import { ModalBase } from 'new-components';
import { useEffect, useState } from 'react';
import { Form, FormControl } from 'react-bootstrap';
import useCreateBillStore from '../store/store';
import { IMaskInput } from 'react-imask';
import _ from 'lodash';

interface Props {
  show: boolean;
  onHide: () => void;
  onSuccessGenerateCode: (code: {
    qrBase64: string;
    bnplUrl: string;
    orderNumber: string;
    amount: number;
  }) => void;
  onFailGenerateCode: () => void;
}

const initialBill = {
  orderNumber: '',
  paymentSum: '',
  bin: ''
};

export const CreateInstallmentBill = ({
  show,
  onHide,
  onSuccessGenerateCode,
  onFailGenerateCode
}: Props) => {
  const [bill, setBill] = useState(initialBill);
  const [loading, setLoading] = useState(false);
  const [existOrderId, setExistOrderId] = useState(false);
  const binFromLocStor = localStorage.getItem('bin');
  const setTempBnplId = useCreateBillStore(state => state.setTempBnplId);

  const isSmallerSum = parseInt(bill.paymentSum) < 15000;

  const resetBill = () => {
    setBill(initialBill);
  };

  useEffect(() => {
    if (!show) {
      resetBill();
      setLoading(false);
      setExistOrderId(false);
    }
  }, [show]);

  useEffect(() => {
    if (_.isEqual(bill, initialBill)) {
      return;
    }
  }, [bill.orderNumber]);

  const submitData = async () => {
    try {
      const res = await getInstallmentQrCodeAsync({
        orderNumber: bill.orderNumber,
        sum: parseInt(bill.paymentSum),
        bin: Number(binFromLocStor)
      });
      if (res?.data && res?.data?.errorCode === 400) {
        setExistOrderId(true);
      }
      if (res?.data.errorCode === 0) {
        console.log('res.data.bnplId', res.data.bnplId);
        onSuccessGenerateCode(res?.data);
        setTempBnplId(res.data.bnplId);
      }
    } catch (err) {
      console.log(err);
      onFailGenerateCode();
    } finally {
      setLoading(false);
    }
  };

  const handleOnClick = () => {
    setLoading(true);
    submitData();
  };

  const handleCloseClick = () => {
    onHide();
    resetBill();
    setLoading(false);
  };

  const areEmptyFields =
    bill.orderNumber.length === 0 || bill.paymentSum.length === 0;

  const handleKeyDown = (event: React.KeyboardEvent<HTMLFormElement>) => {
    if (event.key !== 'Enter') {
      return;
    }
    if (areEmptyFields) {
      return;
    }
    if (loading) {
      return;
    }
    submitData();
  };

  return (
    <ModalBase
      title="Создать счет на рассрочку"
      show={show}
      onHide={handleCloseClick}
    >
      <Form className="w-100" onKeyDown={handleKeyDown}>
        <div className="mb-3">
          <Form.Group className="mb-3">
            <label
              style={{
                fontSize: '14px',
                lineHeight: '18px',
                fontWeight: 700
              }}
              className="modal-label"
            >
              Номер заказа
            </label>
            <FormControl
              id="984576984576945"
              className={`mb-2 ${existOrderId ? 'border-danger' : ''}`}
              value={bill.orderNumber}
              onChange={e =>
                setBill(prev => ({ ...prev, orderNumber: e.target.value }))
              }
              // @ts-expect-error nothing
              customInput={Form.Control}
            />
            {existOrderId ? (
              <Form.Text style={{ fontSize: '14px', color: 'red' }}>
                Данный номер заказа уже используется
              </Form.Text>
            ) : null}
          </Form.Group>

          <Form.Group>
            <label
              style={{
                fontSize: '14px',
                lineHeight: '18px',
                fontWeight: 700
              }}
              className="create-installment-bill-label"
            >
              Сумма к оплате
            </label>
            <IMaskInput
              id="056056698406945"
              className={`form-control mb-2 ${
                isSmallerSum ? 'border-danger' : ''
              }`}
              value={bill.paymentSum}
              onAccept={value =>
                setBill(prev => ({ ...prev, paymentSum: value }))
              }
              mask={Number}
              thousandsSeparator=" "
              unmask={true}
            ></IMaskInput>
            <div>
              <FontAwesomeIcon
                className={`me-2`}
                icon={faExclamationCircle}
                style={isSmallerSum ? { color: 'red' } : {}}
              />
              <Form.Text
                style={
                  isSmallerSum
                    ? { fontSize: '14px', color: 'red' }
                    : { fontSize: '14px' }
                }
              >
                Минимальная сумма рассрочки 15 000 ₸
              </Form.Text>
            </div>
          </Form.Group>
        </div>

        <Button
          className="w-100"
          variant="secondary"
          onClick={handleOnClick}
          disabled={loading || areEmptyFields || isSmallerSum}
        >
          {loading ? 'Загрузка...' : 'Сгенерировать QR-код'}
        </Button>
      </Form>
    </ModalBase>
  );
};
