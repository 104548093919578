import { ModalBase } from 'new-components';
import closeCircle from '../../../../../assets/close-circle.svg';
import Button from 'components/base/Button';

type Props = {
  show: boolean;
  onHide: () => void;
};

export const RejectModal = ({ show, onHide }: Props) => {
  return (
    <ModalBase title="" show={show} onHide={onHide}>
      <div className="text-center w-100" style={{ marginTop: '75px' }}>
        <img src={closeCircle} alt="close-icon" />
        <h5 className="mt-4 mb-0" style={{ fontSize: '20px' }}>
          Отказ в рассрочке
        </h5>
        <p style={{ marginTop: '14px', fontSize: '16px' }}>
          На основании решения <br /> клиенту было отказано в рассрочке
        </p>
      </div>

      <Button
        style={{ marginTop: '53px' }}
        className="w-100"
        variant="secondary"
        onClick={onHide}
      >
        Понятно
      </Button>
    </ModalBase>
  );
};
