import Avatar from 'components/base/Avatar';
import { Card, Dropdown } from 'react-bootstrap';
import FeatherIcon from 'feather-icons-react';
import { getAboutMeInfo } from '../api';
import Button from 'components/base/Button';
// import useNavigateUser from 'hooks/useNavigateUserus';
import { useSignOut } from 'hooks/useSignOut';
import { useQuery } from '@tanstack/react-query';

const AboutMeDropdown = () => {
  // const user = useUserStore.use.user();
  // const setUser = useUserStore.use.setUser();
  // const navigateToAuth = useNavigateUser('/auth/sign-in');\

  const handleOnToggle = async () => {
    try {
      const res = await getAboutMeInfo().then(data => {
        return data.data;
      });
      return res;
    } catch (err) {
      console.log(err);
    }
  };

  const { data } = useQuery({
    queryKey: ['aboutMeData'],
    queryFn: getAboutMeInfo,
    refetchOnWindowFocus: false
  });

  // const handleLogout = () => {
  //   console.log('handle Logout...');
  //   // const redirectUri = 'http://localhost:5173/auth/sign-in';
  //   window.location.href = `https://testpaspay-auth.paspay.kz/logout?redirect_uri=${redirectUri}`;
  // };

  return (
    <Dropdown className="h-100" onToggle={handleOnToggle}>
      <Dropdown.Toggle
        className="dropdown-caret-none nav-link pe-0 py-0 lh-1 h-100 d-flex align-items-center"
        variant=""
      >
        <Avatar size="l" />
      </Dropdown.Toggle>
      <Dropdown.Menu
        align="end"
        className="navbar-top-dropdown-menu navbar-dropdown-caret py-0 dropdown-profile shadow border"
      >
        <Card className="position-relative border-0">
          <Card.Body className="p-0">
            {data?.data ? (
              <div className="d-flex flex-column align-items-center justify-content-center gap-2 pt-4 pb-3">
                <Avatar size="xl" />
                <h6 className="text-body-emphasis">{data?.data.legalName}</h6>
                <h6>{data?.data.ceoFio}</h6>
                <h6 className="text-body-emphasis">{`БИН: ${data?.data.bin}`}</h6>
              </div>
            ) : (
              <div className="d-flex flex-column align-items-center justify-content-center gap-2 pt-4 pb-3">
                <Avatar size="xl" />
                <h6 className="text-body-emphasis">Данные не загружены</h6>
              </div>
            )}
            <div className="px-2 pb-2">
              <Button
                className="btn text-danger d-flex w-50"
                // onClick={() => useSignOut(navigateToAuth)}
                onClick={() => useSignOut()}
              >
                <FeatherIcon icon="log-out" className="me-2" size={16} />
                Выйти
              </Button>
            </div>
          </Card.Body>
        </Card>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default AboutMeDropdown;
