import { getOrderPhotoAsync } from 'modules/orders/api';
import { ModalBase } from 'new-components';
import { useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import useOrdersStore from '../../../store/store';

interface Props {
  show: boolean;
  onHide: () => void;
}

export const ShowPhotoModal = ({ show, onHide }: Props) => {
  const [photo, setPhoto] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const dataFromConfirmModal = useOrdersStore(
    state => state.dataFromConfirmModal
  );

  useEffect(() => {
    if (!show || !dataFromConfirmModal) {
      return;
    }

    setLoading(true);
    getOrderPhotoAsync(dataFromConfirmModal.bnplId)
      .then(res => {
        // console.log('image');
        if (res?.data) {
          setPhoto(res.data.data as string);
        }
      })
      .finally(() => setLoading(false));

    return () => {
      setPhoto(null);
    };
  }, [show]);

  return (
    <ModalBase title="Фото" titleCentered show={show} onHide={onHide}>
      <div
        className="mb-3"
        style={{
          color: '#31374A',
          fontSize: '14px',
          lineHeight: '18px',
          fontWeight: 700
        }}
      >
        {dataFromConfirmModal?.buyer}
      </div>
      {loading && <Spinner style={{ width: '56px', height: '56px' }} />}
      {photo && (
        <img
          style={{ objectFit: 'cover' }}
          className="object-fit-cover"
          width={'330px'}
          height={'330px'}
          src={`data:image/jpeg;base64,${photo}`}
          alt="user-photo"
        />
      )}
    </ModalBase>
  );
};
