import { Offcanvas } from 'react-bootstrap';
import { useState } from 'react';
import FeatherIcon from 'feather-icons-react';
import { NotificationCard } from './NotificationCard';
import { addDays, addHours, addMinutes, format } from 'date-fns';
import { ru } from 'date-fns/locale';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faX } from '@fortawesome/free-solid-svg-icons';

interface Notification {
  title: string;
  message: string;
  time: Date;
}

export const NotificationSidebar = () => {
  const [show, setShow] = useState(false);

  const isNotification = false; // ? temporary solution

  // ? temporary solution
  const mockNotifications = [
    {
      title: 'Плановые технические работы на платформе',
      message:
        '10 октября с 12:00 до 18:00 будут проводиться плановые технические работы на сервере. В этот период могут быть недоступны личный кабинет и раздел платежей.',
      time: new Date()
    },
    {
      title: 'Плановые технические работы на платформе',
      message:
        '10 октября с 12:00 до 18:00 будут проводиться плановые технические работы на сервере. В этот период могут быть недоступны личный кабинет и раздел платежей.',
      time: new Date()
    },
    {
      title: 'Плановые технические работы на платформе',
      message:
        '10 октября с 12:00 до 18:00 будут проводиться плановые технические работы на сервере. В этот период могут быть недоступны личный кабинет и раздел платежей.',
      time: addDays(new Date(), 2)
    },
    {
      title: 'Плановые технические работы на платформе',
      message:
        '10 октября с 12:00 до 18:00 будут проводиться плановые технические работы на сервере. В этот период могут быть недоступны личный кабинет и раздел платежей.',
      time: addDays(new Date(), 1)
    },
    {
      title: 'Плановые технические работы на платформе',
      message:
        '10 октября с 12:00 до 18:00 будут проводиться плановые технические работы на сервере. В этот период могут быть недоступны личный кабинет и раздел платежей.',
      time: addDays(addMinutes(new Date(), 40), 1)
    },
    {
      title: 'Плановые технические работы на платформе',
      message:
        '10 октября с 12:00 до 18:00 будут проводиться плановые технические работы на сервере. В этот период могут быть недоступны личный кабинет и раздел платежей.',
      time: addDays(addMinutes(new Date(), 20), 1)
    },
    {
      title: 'Плановые технические работы на платформе',
      message:
        '10 октября с 12:00 до 18:00 будут проводиться плановые технические работы на сервере. В этот период могут быть недоступны личный кабинет и раздел платежей.',
      time: addDays(addHours(new Date(), 2), 2)
    }
  ].sort((a, b) => {
    //@ts-expect-error any
    return new Date(b.time) - new Date(a.time);
  });

  const groupByDate = (notifications: Notification[]) => {
    return notifications.reduce(
      (groups: Record<string, Notification[]>, notification) => {
        const date = new Date(notification.time);
        const formattedDate = format(date, 'dd MMMM y', {
          locale: ru
        }).toUpperCase();

        if (!groups[formattedDate]) {
          groups[formattedDate] = [];
        }
        groups[formattedDate].push(notification);

        return groups;
      },
      {}
    );
  };

  const groupedNotifications = groupByDate(mockNotifications);

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  return (
    <div>
      <FeatherIcon
        className="notification-toggler"
        width={20}
        height={20}
        color="#525B75"
        onClick={handleShow}
        icon="bell"
      />
      {mockNotifications.length > 0 && (
        <span
          className={`${isNotification && 'is-notification'} notification-dot`}
        ></span>
      )}

      <Offcanvas
        className="notifications-sidebar"
        placement="end"
        show={show}
        onHide={handleClose}
      >
        <Offcanvas.Header className="px-4">
          <Offcanvas.Title className="fs-7 ff-gilroy">
            Уведомления
          </Offcanvas.Title>
          <FontAwesomeIcon
            onClick={handleClose}
            className="notifications-close"
            icon={faX}
          />
        </Offcanvas.Header>
        <hr className="my-0" />
        <Offcanvas.Body className="d-flex flex-column p-4 pt-0 scrollbar">
          {Object.keys(groupedNotifications).map((date, index) => (
            <div className="d-flex flex-column gap-2 pt-2" key={index}>
              <div className="notifications-separator fw-lighter text-center mt-1 mb-1">
                {date}
              </div>

              {groupedNotifications[date].map((item, itemIndex) => (
                <NotificationCard
                  key={itemIndex}
                  title={item.title}
                  message={item.message}
                  time={item.time}
                  isNew={index === 0 && itemIndex === 0}
                />
              ))}
            </div>
          ))}
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  );
};
