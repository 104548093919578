import { useState, useEffect } from 'react';
import { faChain, faCopy } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ModalBase } from 'new-components';
import { Form } from 'react-bootstrap';
import { Timer } from 'components/timer/timer';
import useCreateBillStore from '../store/store';
import { separateThousands } from 'helpers';

interface Props {
  bill: {
    orderNumber: string;
    billSum: number;
    qrCode: string;
    url: string;
  };
  show: boolean;
  onHide: () => void;
  closeWSConnection: () => void;
}

export const ScaneQrcode = ({
  show,
  onHide,
  bill,
  closeWSConnection
}: Props) => {
  const [iconColor, setIconColor] = useState<string>('rgb(49, 55, 74)');
  const setOpenConnection = useCreateBillStore(
    state => state.setOpenConnection
  );

  const copyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text).then(
      () => {
        setIconColor('rgb(4 89 181)');
        setTimeout(() => {
          setIconColor('rgb(49, 55, 74)');
        }, 1500);
      },
      err => {
        console.error('Could not copy text: ', err);
      }
    );
  };

  useEffect(() => {
    if (show) {
      setOpenConnection(true);
    }
  }, [show]);

  return (
    <ModalBase
      title="Сканировать QR-код"
      show={show}
      onHide={() => {
        onHide();
        closeWSConnection();
      }}
      titleCentered
    >
      <div className="mb-3 text-center">
        <Timer style={{ marginBottom: '11px' }} time={3_600} />
        <div
          style={{
            marginBottom: '11px',
            color: '#8A94AD',
            fontSize: '14px',
            fontWeight: 700
          }}
        >
          Номер заказа: {bill.orderNumber}
        </div>
        <div
          style={{
            fontSize: '39px',
            lineHeight: '43px',
            fontWeight: 800,
            color: '#31374A'
          }}
        >
          {separateThousands(bill.billSum)} ₸
        </div>
      </div>
      <img
        className="object-fit-cover"
        src={`data:image/jpeg;base64,${bill.qrCode}`}
        style={{ width: '248px', height: '248px' }}
      />
      <div className="d-flex flex-column w-100">
        <div
          className="text-center mb-3"
          style={{
            fontSize: '14px',
            lineHeight: '18px',
            fontWeight: 600,
            color: '#31374A'
          }}
        >
          <div>
            Или отправьте ссылку для <br /> оформления <br />
            рассрочки покупателю
          </div>
        </div>

        <Form.Group className="d-flex align-items-center position-relative">
          <FontAwesomeIcon
            className="position-absolute"
            style={{ left: '16px' }}
            icon={faChain}
          />
          <Form.Control
            style={{
              padding: '14px 40px',
              fontSize: '14px',
              lineHeight: '18px',
              fontWeight: 600
            }}
            value={bill.url}
            readOnly
          />
          <FontAwesomeIcon
            className="position-absolute cursor-pointer"
            style={{ right: '16px', color: iconColor }}
            icon={faCopy}
            onClick={() => copyToClipboard(bill.url)}
          />
        </Form.Group>
      </div>
    </ModalBase>
  );
};
