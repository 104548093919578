import { router } from 'Routes';
import AppProvider from 'providers/AppProvider';
import BreakpointsProvider from 'providers/BreakpointsProvider';
import SettingsPanelProvider from 'providers/SettingsPanelProvider';
import ReactDOM from 'react-dom/client';
import { RouterProvider } from 'react-router-dom';
import { PersistQueryClientProvider } from '@tanstack/react-query-persist-client';
import { QueryClient } from '@tanstack/react-query';
import { createAsyncStoragePersister } from '@tanstack/query-async-storage-persister';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      gcTime: 100 * 60 * 60 //60 mins
    }
  }
});

const asyncStoragePersister = createAsyncStoragePersister({
  storage: window.localStorage
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <AppProvider>
    <SettingsPanelProvider>
      <BreakpointsProvider>
        <PersistQueryClientProvider
          client={queryClient}
          persistOptions={{ persister: asyncStoragePersister }}
        >
          <RouterProvider router={router} />
        </PersistQueryClientProvider>
      </BreakpointsProvider>
    </SettingsPanelProvider>
  </AppProvider>
);
