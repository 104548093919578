import CryptoJS from 'crypto-js';

class SecureLocalStorageHelper {
  private static CRYPTO_SECRET_KEY = 'PAYPASS_SECRET_KEY';

  private static BEARER_TOKEN_KEY = 'BEARER_TOKEN_KEY';

  private static REFRESH_TOKEN_KEY = 'REFRESH_TOKEN_KEY';

  private static encrypt(value: string) {
    return CryptoJS.AES.encrypt(value, this.CRYPTO_SECRET_KEY).toString();
  }

  private static decrypt(value: string) {
    return CryptoJS.AES.decrypt(value, this.CRYPTO_SECRET_KEY).toString(
      CryptoJS.enc.Utf8
    );
  }

  static getBearerToken() {
    const encrypted = localStorage.getItem(this.BEARER_TOKEN_KEY);
    if (encrypted === null) {
      return null;
    }
    const decrypted = this.decrypt(encrypted);
    return JSON.parse(decrypted) as string;
  }

  static setBearerToken(token: string) {
    const json = JSON.stringify(token);
    const encrypted = this.encrypt(json);
    localStorage.setItem(this.BEARER_TOKEN_KEY, encrypted);
  }

  static hasBearerToken() {
    return this.BEARER_TOKEN_KEY in localStorage;
  }

  static getRefreshToken() {
    const encrypted = localStorage.getItem(this.REFRESH_TOKEN_KEY);
    if (encrypted === null) {
      return null;
    }
    const decrypted = this.decrypt(encrypted);
    return JSON.parse(decrypted) as string;
  }

  static setRefreshToken(token: string) {
    const json = JSON.stringify(token);
    const encrypted = this.encrypt(json);
    localStorage.setItem(this.REFRESH_TOKEN_KEY, encrypted);
  }

  static hasRefreshToken() {
    return this.REFRESH_TOKEN_KEY in localStorage;
  }

  static clearAll() {
    localStorage.clear();
  }
}

export default SecureLocalStorageHelper;
