import { ColumnDef } from '@tanstack/react-table';
import { OrderType } from './types';
import { OrderStatusItem } from 'new-components/status-item';
import { formatDate, separateThousands } from 'helpers';
import { OrderStatus } from 'modules/orders/enums';

type ExpectingOrderStatus =
  | OrderStatus.QR_CODE_GENERATION
  | OrderStatus.CODE_CHECKING
  | OrderStatus.CLIENT_VERIFICATION
  | OrderStatus.WAITING_ISSUE;

const expectationTitle: { [key in ExpectingOrderStatus]: string | null } = {
  [OrderStatus.QR_CODE_GENERATION]: null,
  [OrderStatus.CLIENT_VERIFICATION]: 'Идет проверка',
  [OrderStatus.CODE_CHECKING]: 'Подтвердить код',
  [OrderStatus.WAITING_ISSUE]: null
};

export const ordersTableColumns: ColumnDef<OrderType>[] = [
  {
    accessorKey: 'orderNumber',
    header: '№ заказа',
    meta: { headerProps: { style: { width: '11%' } } }
  },
  {
    accessorKey: 'operationDate',
    header: 'Дата операции',
    meta: { headerProps: { style: { width: '14%' } } },
    cell: cell => <>{formatDate(new Date(cell.getValue() as string))}</>
  },
  {
    accessorKey: 'sum',
    header: 'Сумма',
    meta: { headerProps: { style: { width: '11%' } } },
    cell: cell => {
      const sum = parseInt(cell.getValue() as string);
      return <>{separateThousands(sum) + ' ₸'}</>;
    }
  },
  {
    accessorKey: 'period',
    header: 'Период',
    meta: { headerProps: { style: { width: '8%' } } },
    cell: cell => {
      const value = cell.getValue() as number | null;
      if (!value) {
        return '';
      }
      return value + ' мес';
    }
  },
  {
    accessorKey: 'buyer',
    header: 'Покупатель',
    enableSorting: false,
    meta: { headerProps: { style: { width: '14%' } } }
  },
  {
    accessorKey: 'manager',
    header: 'Менеджер',
    enableSorting: false,
    meta: { headerProps: { style: { width: '14%' } } }
  },
  {
    accessorKey: 'status',
    header: 'Статус',
    enableSorting: false,
    meta: {
      headerProps: { className: 'text-end' },
      cellProps: { className: 'text-end' }
    },
    cell: cell => {
      const value = cell.getValue() as {
        id: string;
        time: string | null;
        status: number;
      };
      const orderStatus = Number.isNaN(value.status)
        ? OrderStatus.CANCEL
        : value.status;
      const isExpecting =
        OrderStatus.QR_CODE_GENERATION ||
        OrderStatus.CODE_CHECKING ||
        OrderStatus.CLIENT_VERIFICATION ||
        OrderStatus.WAITING_ISSUE;

      return (
        <>
          {isExpecting && (
            <div
              className="d-inline-flex me-3"
              style={{ fontSize: '14px', fontWeight: 600, color: '#8A94AD' }}
            >
              {expectationTitle[orderStatus as ExpectingOrderStatus]}
            </div>
          )}
          <OrderStatusItem
            id={value.id}
            status={orderStatus}
            time={value.time ?? undefined}
          />
        </>
      );
    }
  }
];
