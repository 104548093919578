import { useDebouncedCallback } from 'use-debounce';
import SearchBox from 'components/common/SearchBox';
import { useLoaderData, useSearchParams } from 'react-router-dom';
import { SearchParamsKey } from 'enums';
import { useOrdersSearchStore } from './store';
import { OrderType } from '../orders-list/components/table/types';

const OrdersSearchFilter = () => {
  const [searchParams, setSearchParams] = useSearchParams({
    [SearchParamsKey.Search]: ''
  });
  const { ordersList } = useLoaderData() as { ordersList: OrderType[] };
  const setSearch = useOrdersSearchStore.use.setSearch();

  const searchValue = searchParams.get(SearchParamsKey.Search);

  const debounced = useDebouncedCallback(value => {
    setSearch(value);
    if (value === '') {
      searchParams.delete(SearchParamsKey.Search);
    } else {
      searchParams.set(SearchParamsKey.Search, value);
    }
    setSearchParams(searchParams);
  }, 1000);

  const isNotFound =
    ordersList.length === 0 && searchValue !== '' && searchValue !== null;

  return (
    <div>
      <SearchBox
        defaultValue={searchValue ?? ''}
        placeholder="Поиск по номеру заказа"
        onChange={e => debounced(e.target.value)}
      />
      {isNotFound && <div style={{ color: 'red' }}>Ничего не найдено</div>}
    </div>
  );
};

export default OrdersSearchFilter;
