import Logo from 'components/common/Logo';
import { PropsWithChildren } from 'react';
import { Card, Col, Container, Row } from 'react-bootstrap';
import { Link, Outlet } from 'react-router-dom';
import vectorLogo from 'assets/img/Vector.svg';

interface AuthCardLayoutProps {
  logo?: boolean;
  className?: string;
}

const ResetPasswordCardLayout = ({
  logo = true
}: PropsWithChildren<AuthCardLayoutProps>) => {
  return (
    <Container fluid className="bg-body-tertiary dark__bg-gray-1200">
      <div
        className="bg-holder bg-auth-card-overlay"
        style={{
          background:
            'linear-gradient(251.8deg, #F8EEFE -8.18%, #FEEEEE 99.03%)'
        }}
      />

      <Row className="flex-center position-relative min-vh-100 g-0 py-5">
        <Col xs={11} sm={10} xl={3}>
          <Card className="border border-translucent auth-card shadow p-3 mb-5 bg-white rounded">
            <Card.Body>
              <Row className="align-items-center gx-0 gy-7">
                <Col className="mx-auto">
                  {logo && (
                    <div className="text-center">
                      <Link
                        to="/"
                        className="d-inline-block text-decoration-none mb-4"
                      >
                        <Logo
                          text={false}
                          width={38}
                          className="fw-bolder fs-5 d-inline-block bg-light px-4 py-3 rounded"
                          logo={vectorLogo}
                        />
                      </Link>
                    </div>
                  )}
                  <div className="auth-form-box">
                    <Outlet />
                  </div>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default ResetPasswordCardLayout;
