import Button from 'components/base/Button';
import { ModalBase } from 'new-components';
import { Form } from 'react-bootstrap';
import warning from '../../../../../assets/warning.svg';
import { useState } from 'react';
import { returnOrderAsync } from 'modules/orders/api';

type Props = {
  orderId: string;
  show: boolean;
  onHide: () => void;
  onSuccessReturn: () => void;
  onFailReturn: () => void;
};

export const MakeReturnModal = ({
  show,
  onHide,
  orderId,
  onSuccessReturn,
  onFailReturn
}: Props) => {
  const [returnReason, setReturnReason] = useState<'client' | 'other'>(
    'client'
  );
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    console.log(returnReason);
    returnOrderAsync(Number(orderId), returnReason)
      .then(onSuccessReturn)
      .catch(onFailReturn);
  };

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setReturnReason(event.target.value as 'client' | 'other');
  };

  return (
    <ModalBase title="Полный возврат операции" show={show} onHide={onHide}>
      <Form className="w-100" onSubmit={handleSubmit}>
        <div>
          <h5>Причина возврата</h5>

          <div className="mt-3">
            <Form.Check
              defaultChecked={returnReason === 'client'}
              type="radio"
              label="По просьбе клиента"
              value="client"
              name="returnReason"
              onChange={handleRadioChange}
            />
            <Form.Check
              defaultChecked={returnReason === 'other'}
              type="radio"
              label="Другая причина"
              id="other"
              value="other"
              name="returnReason"
              onChange={handleRadioChange}
            />
          </div>

          <div
            className="d-flex mt-3"
            style={{ alignItems: 'center', padding: '8px 9px 8px 17px' }}
          >
            <img className="me-2" src={warning} alt="warning" />
            <p className="m-0">
              Возврат осуществляется в течение 14 рабочих банковских дней на
              счет клиента
            </p>
          </div>
        </div>

        <div style={{ marginTop: '34px' }} className="d-flex w-100">
          <Button className="w-100" onClick={onHide}>
            Отменить
          </Button>
          <Button className="w-100" variant="secondary" type="submit">
            Сделать возврат
          </Button>
        </div>
      </Form>
    </ModalBase>
  );
};
