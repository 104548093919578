import { useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import qs from 'qs';
import { SecureLocalStorageHelper } from 'local-storage';
import { useUserStore } from '../store';

const Callback = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const code = urlParams.get('code');
  const navigate = useNavigate();
  const setIsUserValid = useUserStore(state => state.setIsUserValid);

  useEffect(() => {
    const sendTokenRequest = async () => {
      try {
        const data = {
          grant_type: 'authorization_code',
          code,
          // redirect_uri: 'http://localhost:5173/callback',
          redirect_uri: 'https://cabinet.paspay.kz/callback',
          code_verifier: 'na7DyOcnRXVoACspvP38NsBiG_2fDapMxuLmvX3pq-Q',
          client_id: 'paspay-cabinet-web-client',
          client_secret:
            'CUEbd9WjPqRXPW8SDt0GjikKTr5R45mZykJ5LIujl7P5sIGG5ZzBL4FXmqmBzoJQ'
        };

        const response = await axios.post(
          'https://auth.paspay.kz/oauth2/token',
          qs.stringify(data),
          {
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
          }
        );
        console.log('response:::', response.data);
        // localStorage.setItem('accessToken', response.data.access_token);
        // localStorage.setItem('refreshToken', response.data.refresh_token);
        setIsUserValid(true);
        SecureLocalStorageHelper.setBearerToken(response.data.access_token);
        SecureLocalStorageHelper.setRefreshToken(response.data.refresh_token);

        navigate('/');
      } catch (error) {
        console.error(
          'Error fetching token:',
          //@ts-expect-error nothing
          error.response ? error.response.data : error.message
        );
      }
    };

    if (code) {
      sendTokenRequest();
    }
  }, [code, navigate]);

  return null;
};

export default Callback;
