import { ModalBase } from 'new-components';
import successCircle from '../../../../../assets/success-circle.svg';
import Button from 'components/base/Button';

type Props = {
  show: boolean;
  onHide: () => void;
};

export const SuccessModal = ({ show, onHide }: Props) => {
  return (
    <ModalBase title="" show={show} onHide={onHide}>
      <div className="text-center w-100" style={{ marginTop: '75px' }}>
        <img src={successCircle} alt="success-circle" />
        <h5 className="mt-4 mb-0" style={{ fontSize: '20px' }}>
          Рассрочка оформлена
        </h5>
        <p style={{ marginTop: '14px', fontSize: '16px' }}>
          Выдайте заказ клиенту
        </p>
      </div>

      <Button
        style={{ marginTop: '76px' }}
        className="w-100"
        variant="secondary"
      >
        Понятно
      </Button>
    </ModalBase>
  );
};
