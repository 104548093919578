import ThemeToggler from 'components/common/ThemeToggler';
import { Col, Container, Row } from 'react-bootstrap';
import { Outlet } from 'react-router-dom';

const AuthLayout = () => {
  return (
    <Container fluid className="dark__bg-gray-1200">
      <div
        className="bg-holder bg-auth-card-overlay"
        style={{
          background:
            'linear-gradient(251.8deg, #F8EEFE -8.18%, #FEEEEE 99.03%)'
        }}
      />

      <Row className="flex-center position-relative min-vh-100 g-0 py-5">
        <ThemeToggler className="top-0 end-0 m-5 float-end w-auto position-absolute" />
        <Col xs={11} sm={10} xl={3}>
          <div>
            <Outlet />
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default AuthLayout;
