import { faFilter } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'components/base/Button';
import { useState } from 'react';
import { FilterModal } from './modals/FilterModal';

const OrdersFilter = () => {
  const [showFilter, setShowFilter] = useState(false);

  return (
    <>
      <FilterModal
        show={showFilter}
        onHide={() => setShowFilter(false)}
        onCancelBtnClick={() => setShowFilter(false)}
        onApplyBtnClick={() => setShowFilter(false)}
      />
      <Button
        variant="outline-secondary"
        style={{ padding: '12px 16px' }}
        startIcon={<FontAwesomeIcon icon={faFilter} size="lg" />}
        onClick={() => setShowFilter(true)}
      />
    </>
  );
};

export default OrdersFilter;
