import {
  faChevronLeft,
  faChevronRight
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import usePagination from 'hooks/usePagination';
import { Col, Pagination, Row } from 'react-bootstrap';
import { useLoaderData, useSearchParams } from 'react-router-dom';
import { useOrdersPaginationStore } from './store';
import { SearchParamsKey } from 'enums';

const OrdersFooter = () => {
  const { totalPages } = useLoaderData() as {
    totalItems: number;
    totalPages: number;
  };

  const [searchParams, setSearchParams] = useSearchParams({
    [SearchParamsKey.PageIndex]: '0'
  });
  const setPageIndex = useOrdersPaginationStore.use.setPageIndex();

  const currentPageIndex = Number(searchParams.get(SearchParamsKey.PageIndex));

  const { hasPrevEllipsis, hasNextEllipsis, visiblePaginationItems } =
    usePagination({
      currentPageNo: currentPageIndex + 1,
      totalPage: totalPages,
      maxPaginationButtonCount: 5
    });

  const goToPage = (page: number) => {
    setPageIndex(page);
    searchParams.set(SearchParamsKey.PageIndex, page.toString());
    setSearchParams(searchParams);
  };

  return (
    <Row className="align-items-center py-1">
      <Col className="d-flex fs-9">
        {/* <p className="mb-0 d-none d-sm-block me-3 fw-semibold text-body">
          {10 * currentPageIndex + 1} to {10 * (currentPageIndex + 1)}
          <span className="text-body-tertiary"> items of </span>
          {totalItems}
        </p> */}
      </Col>
      <Col xs="auto">
        {totalPages > 0 && (
          <Pagination className="mb-0 justify-content-center">
            <Pagination.Prev
              disabled={currentPageIndex === 0}
              onClick={() => {
                goToPage(currentPageIndex - 1);
              }}
            >
              <FontAwesomeIcon icon={faChevronLeft} />
            </Pagination.Prev>

            {hasPrevEllipsis && (
              <>
                <Pagination.Item
                  active={currentPageIndex === 0}
                  onClick={() => {
                    goToPage(0);
                  }}
                >
                  1
                </Pagination.Item>
                <Pagination.Ellipsis disabled />
              </>
            )}

            {visiblePaginationItems.map(page => (
              <Pagination.Item
                key={page}
                active={currentPageIndex === page - 1}
                onClick={() => {
                  goToPage(page - 1);
                }}
              >
                {page}
              </Pagination.Item>
            ))}

            {hasNextEllipsis && (
              <>
                <Pagination.Ellipsis disabled />
                <Pagination.Item
                  active={currentPageIndex === totalPages - 1}
                  onClick={() => {
                    goToPage(totalPages - 1);
                  }}
                >
                  {totalPages}
                </Pagination.Item>
              </>
            )}
            <Pagination.Next
              disabled={currentPageIndex === totalPages - 1}
              onClick={() => {
                goToPage(currentPageIndex + 1);
              }}
            >
              <FontAwesomeIcon icon={faChevronRight} />
            </Pagination.Next>
          </Pagination>
        )}
      </Col>
    </Row>
  );
};

export default OrdersFooter;
