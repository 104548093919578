import { faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import Button from 'components/base/Button';
import { Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const ForgotPasswordForm = ({
  layout
}: {
  layout?: 'simple' | 'card' | 'split';
}) => {
  return (
    <div className={classNames({ 'px-xxl-5': !(layout === 'split') })}>
      <div
        className={classNames('text-center', { 'mb-6': !(layout === 'split') })}
      >
        <h4 className="text-body-highlight">Восстановление пароля</h4>
        <p className="text-body-tertiary mb-5">
          Получите доступ к своей учетной записи
        </p>
        <Form.Group className="mb-3 text-start">
          <Form.Label htmlFor="email">Введите Email</Form.Label>
          <div className="form-icon-container">
            <Form.Control
              id="email"
              type="email"
              className="form-icon-input"
              placeholder="email"
            />
            <FontAwesomeIcon
              icon={faUser}
              className="text-body fs-9 form-icon"
            />
          </div>
        </Form.Group>
        <Link to="/reset/2FA">
          <Button variant="secondary" className="w-100 mb-3">
            Продолжить
          </Button>
        </Link>
        <Link to="/auth/sign-in">
          <Button className="w-100 mb-3">Отмена</Button>
        </Link>
      </div>
    </div>
  );
};

export default ForgotPasswordForm;
