import { SecureLocalStorageHelper } from 'local-storage';
import { LoaderFunctionArgs, redirect } from 'react-router-dom';
import { OrdersListResponseType, getOrdersListAsync } from 'modules/orders';
import { SearchParamsKey } from 'enums';
import { OrderType } from 'modules/orders/orders-list/components/table/types';
import { queryClient } from 'index';

export const loader = async ({ request }: LoaderFunctionArgs) => {
  // const isAuth = SecureLocalStorageHelper.hasBearerToken();

  // if (!isAuth) {
  //   return redirect('/auth/sign-in');
  // }

  // if (localStorage.getItem('filterParams')) {
  //   new URL(request.url).searchParams.set(SearchParamsKey.PaymentStatus, '');
  //   new URL(request.url).searchParams.set(
  //     SearchParamsKey.PaymentStatus,
  //     //@ts-expect-error str
  //     JSON.parse(localStorage.getItem('filterParams'))
  //   );
  // }
  const pageIndex =
    new URL(request.url).searchParams.get(SearchParamsKey.PageIndex) ??
    undefined;
  const startDate =
    new URL(request.url).searchParams.get(SearchParamsKey.StartDate) ??
    undefined;
  const endDate =
    new URL(request.url).searchParams.get(SearchParamsKey.EndDate) ?? undefined;
  const orderNumber =
    new URL(request.url).searchParams.get(SearchParamsKey.Search) ?? undefined;
  // const paymentStatus =
  // new URL(request.url).searchParams.getAll(SearchParamsKey.PaymentStatus) ??
  // undefined;
  const paymentStatus =
    new URL(request.url).searchParams
      .getAll(SearchParamsKey.PaymentStatus)
      .map(status => {
        if (status.length > 0) {
          return status.slice(-1);
        }
        return status;
      }) ?? undefined;

  console.log(
    'from url:',
    new URL(request.url).searchParams.getAll(SearchParamsKey.PaymentStatus)
  );
  // const paymentStatus =
  //   Object.values(
  //     new URL(request.url).searchParams.getAll(SearchParamsKey.PaymentStatus)
  //   ) ?? undefined;

  console.log(paymentStatus);

  const res = await getOrdersListAsync(Number(pageIndex ?? 0), {
    startDate,
    endDate,
    orderNumber,
    paymentStatus
  });
  if (!res) {
    console.log('data result is null');
    return;
  }
  if (res?.status === 401) {
    SecureLocalStorageHelper.clearAll();
    localStorage.clear();
    queryClient.clear();
    console.log('sign out from loader');
    return redirect('/auth/sign-in');
  }

  return {
    totalItems: res?.data.totalElements,
    totalPages: res?.data.totalPages,
    ordersList: mapResponse(res.data.responseList),
    paymentStatus
  };
};

const mapResponse = (
  res: OrdersListResponseType['responseList']
): OrderType[] =>
  res.map(item => ({
    orderNumber: item.orderId,
    bnplNumber: item.bnplId,
    operationDate: item.createdAt,
    sum: item.amount,
    period: item.paymentCount,
    buyer: item.buyer,
    manager: item.manager,
    status: {
      time: null,
      status: parseInt(item.status as string)
    }
  }));
