import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { Icon, UilChartPie } from '@iconscout/react-unicons';

export interface Route {
  name: string;
  icon?: IconProp | string | string[];
  iconSet?: 'font-awesome' | 'feather' | 'unicons';
  pages?: Route[];
  disabled?: boolean;
  path?: string;
  pathName?: string;
  flat?: boolean;
  topNavIcon?: string;
  dropdownInside?: boolean;
  active?: boolean;
  new?: boolean;
  hasNew?: boolean;
}

export interface RouteItems {
  label: string;
  horizontalNavLabel?: string;
  icon: Icon;
  labelDisabled?: boolean;
  pages: Route[];
  megaMenu?: boolean;
  active?: boolean;
}

export const routes: RouteItems[] = [
  {
    label: 'Мой кабинет',
    horizontalNavLabel: 'home',
    active: true,
    icon: UilChartPie,
    labelDisabled: false,
    pages: [
      {
        name: 'Рассрочка',
        icon: 'calendar',
        active: true,
        flat: true,
        pages: [
          {
            name: 'Заказы',
            path: '/installment/orders',
            pathName: 'installment-orders',
            active: true
          },
          // {
          //   name: 'Журнал событий',
          //   path: '/installment/event-log',
          //   pathName: 'installment-event-log',
          //   active: true
          // },
          {
            name: 'История выплат',
            path: '/installment/payment-history',
            pathName: 'installment-payment-history',
            active: true
          }
        ]
      },
      {
        name: 'Сотрудники',
        icon: 'users',
        active: true,
        flat: true,
        pages: []
      },
      {
        name: 'Филиалы',
        icon: 'pie-chart',
        active: true,
        flat: true,
        pages: []
      },
      {
        name: 'Транзакции',
        icon: 'calendar',
        active: true,
        path: '/balance-and-transactions'
        // pathName: 'installment-payment-history'
      }
    ]
  }
];
