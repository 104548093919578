import dayjs from 'dayjs';
import { useSearchParams } from 'react-router-dom';
import { DropdownPicker } from './components/dropdown-picker';
import { SearchParamsKey } from 'enums';
import { useGlobalStore } from 'global-store';

const OrdersDatePicker = () => {
  const [searchParams, setSearchParams] = useSearchParams({
    [SearchParamsKey.StartDate]: dayjs(new Date()).format('YYYY-MM-DD'),
    [SearchParamsKey.EndDate]: dayjs(new Date()).format('YYYY-MM-DD')
  });

  const setDate = useGlobalStore.use.setOrdersDate();

  const handleSetRange = (range: { startDate: Date; endDate: Date }) => {
    const startDate = dayjs(range.startDate).format('YYYY-MM-DD');
    const endDate = dayjs(range.endDate).format('YYYY-MM-DD');

    searchParams.set(SearchParamsKey.StartDate, startDate);
    searchParams.set(SearchParamsKey.EndDate, endDate);
    searchParams.delete(SearchParamsKey.PageIndex);

    setDate({ startDate, endDate });
    setSearchParams(searchParams);
  };

  return (
    <DropdownPicker
      className="me-2"
      range={{
        startDate: dayjs(searchParams.get(SearchParamsKey.StartDate)).toDate(),
        // endDate: dayjs(searchParams.get(SearchParamsKey.EndDate)).toDate()
        endDate: new Date()
      }}
      setRange={handleSetRange}
    />
  );
};

export default OrdersDatePicker;
