import { useRef, useState } from 'react';

type SocketMessageType = {
  bnpl_id: number;
  error_code: number;
  error_message: string;
  rest_of_time: string;
  status_id: number;
};

const useSocket = () => {
  // const URL = 'wss://test-bnpl.paspay.kz/status-ws';
  const URL = 'wss://bnpl.paspay.kz/status-ws';
  const socketRef = useRef<WebSocket | null>(null);
  const [data, setData] = useState<SocketMessageType[]>([]);

  const connect = (onSuccess?: () => void) => {
    if (socketRef.current) {
      console.log('SOCKET IS ALREADY CONNECTED');
      return;
    }
    console.log('TRY CONNECT...');
    socketRef.current = new WebSocket(URL);

    socketRef.current.onopen = () => {
      console.log('success !!! !!!');
      onSuccess && onSuccess();
    };

    socketRef.current.onmessage = async event => {
      const message = JSON.parse(event.data) as SocketMessageType[];
      setData(message);
    };

    socketRef.current.onerror = () => {
      console.log('socket error!!! !!!!');
    };

    socketRef.current.onclose = () => {
      console.log('close socket!!! !!!');
      console.log(event);
    };
  };

  const disconnect = () => {
    console.log('try disconnect...');
    if (!socketRef.current) {
      console.log('socket is already disconnected');
      return;
    }

    socketRef.current.close();
    socketRef.current = null;
  };

  const getOrdersInfo = (ids: number[]) => {
    const message = JSON.stringify({ bnpl_id_list: ids });

    if (!socketRef.current) {
      console.log('SOCKET IS NOT CONNECTED!!! MESSAGE WILL NOT BE SENDED!!!');
      return;
    }

    socketRef.current.send(message);
  };

  return {
    connect,
    disconnect,
    getOrdersInfo,
    data
  };
};

export default useSocket;
