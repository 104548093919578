import { SearchParamsKey } from 'enums';
import { queryClient } from 'index';
import { SecureLocalStorageHelper } from 'local-storage';
import { getTransactionsListAsync } from 'modules/transactions/api/transactions-list';
import { LoaderFunctionArgs, redirect } from 'react-router-dom';

export const loader = async ({ request }: LoaderFunctionArgs) => {
  // const isAuth = SecureLocalStorageHelper.hasBearerToken();
  // if (!isAuth) {
  //   return redirect('/auth/sign-in');
  // }

  const pageIndex =
    new URL(request.url).searchParams.get(SearchParamsKey.PageIndex) ??
    undefined;
  const fromDate =
    new URL(request.url).searchParams.get(SearchParamsKey.StartDate) ??
    undefined;
  const tillDate =
    new URL(request.url).searchParams.get(SearchParamsKey.EndDate) ?? undefined;

  const res = await getTransactionsListAsync(Number(pageIndex ?? 0), {
    fromDate: fromDate,
    tillDate: tillDate
  });
  if (res?.data.errorCode === 400) {
    console.log('400 error: ', res);
    return;
  }
  if (res?.data.errorCode === 401) {
    SecureLocalStorageHelper.clearAll();
    localStorage.clear();
    queryClient.clear();
    console.log('sign out from loader');
    return redirect('/auth/sign-in');
  }

  return {
    total_pages: res?.data.total_pages,
    transactions: res?.data.transactions
  };
};
