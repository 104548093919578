import {
  faCheck,
  faClock,
  faClose,
  faUndo,
  faWarning
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { formatMMSSToSeconds, formatSecondsToHHMMSS } from 'helpers';
import { useTimer } from 'hooks/useTimer';
import { OrderStatus } from 'modules/orders/enums';
import { useEffect } from 'react';

interface Props {
  id: string;
  status: OrderStatus;
  time?: string;
}

const styles = {
  [OrderStatus.QR_CODE_GENERATION]: {
    color: '#005585',
    backgroundColor: '#C7EBFF',
    borderColor: '#96D9FF'
  },
  [OrderStatus.CLIENT_VERIFICATION]: {
    color: '#005585',
    backgroundColor: '#C7EBFF',
    borderColor: '#96D9FF'
  },
  [OrderStatus.CODE_CHECKING]: {
    color: '#005585',
    backgroundColor: '#C7EBFF',
    borderColor: '#96D9FF'
  },
  [OrderStatus.WAITING_ISSUE]: {
    color: '#005585',
    backgroundColor: '#C7EBFF',
    borderColor: '#96D9FF'
  },
  [OrderStatus.SUCCESS]: {
    color: '#1C6C09',
    backgroundColor: '#D9FBD0',
    borderColor: '#BEE8B4'
  },
  [OrderStatus.CANCEL]: {
    color: '#B81800',
    backgroundColor: '#FFE0DB',
    borderColor: '#FABCB3'
  },
  [OrderStatus.RETURN]: {
    color: '#31374A',
    backgroundColor: '#E3E6ED',
    borderColor: '#CBD0DD'
  },
  [OrderStatus.REJECT]: {
    color: '#B81800',
    backgroundColor: '#FFE0DB',
    borderColor: '#FABCB3'
  }
};

const orderStatus = {
  [OrderStatus.SUCCESS]: {
    title: 'Успешный',
    icon: faCheck
  },
  [OrderStatus.QR_CODE_GENERATION]: {
    title: 'В ожидании',
    icon: faClock
  },
  [OrderStatus.CLIENT_VERIFICATION]: {
    title: 'В ожидании',
    icon: faClock
  },
  [OrderStatus.CODE_CHECKING]: {
    title: 'В ожидании',
    icon: faClock
  },
  [OrderStatus.WAITING_ISSUE]: {
    title: 'В ожидании',
    icon: faClock
  },
  [OrderStatus.RETURN]: {
    title: 'Возврат',
    icon: faUndo
  },
  [OrderStatus.REJECT]: {
    title: 'Отказ',
    icon: faWarning
  },
  [OrderStatus.CANCEL]: {
    title: 'Отменен',
    icon: faClose
  }
};

const OrderStatusItem = ({ status, time }: Props) => {
  const isExpecting =
    status === OrderStatus.QR_CODE_GENERATION ||
    status === OrderStatus.CODE_CHECKING ||
    status === OrderStatus.CLIENT_VERIFICATION ||
    status === OrderStatus.WAITING_ISSUE;

  const { start, time: seconds, reset } = useTimer(0);

  // useEffect(() => {
  //   console.log('IDD:::', id);
  //   console.log('status:::', status);
  //   console.log('time:::', formatSecondsToHHMMSS(seconds));
  // }, [time]);

  useEffect(() => {
    // console.log('useEffect() in order item');
    if (!isExpecting) {
      return;
    }

    // console.log('IDD:::', id);
    // console.log('status:::', status);
    // console.log('time:::', time);
    start(!time ? undefined : formatMMSSToSeconds(time));

    return () => {
      stop();
      reset();
    };
  }, [time]);

  return (
    <div
      className="d-inline-flex justify-content-end align-items-center rounded"
      style={{
        padding: '4.5px 6px',
        backgroundColor: styles[status].backgroundColor,
        color: styles[status].color,
        borderColor: styles[status].borderColor
      }}
    >
      <div className="me-1" style={{ fontSize: '12px', fontWeight: 700 }}>
        {orderStatus[status].title.toUpperCase()}
      </div>
      {isExpecting && (
        <div className="me-1" style={{ fontSize: '12px', fontWeight: 700 }}>
          {formatSecondsToHHMMSS(seconds, true)} <br />
          {/* {seconds}
          <br />
          {time} */}
        </div>
      )}
      <FontAwesomeIcon icon={orderStatus[status].icon} size="xs" />
    </div>
  );
};

export default OrderStatusItem;
