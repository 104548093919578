import { faClose, faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import Button from 'components/base/Button';
import { SearchParamsKey } from 'enums';
import useTransactionsSearchStore from 'modules/transactions/transactions-search-filter/store/store';
import { CSSProperties } from 'react';
import { Form, FormControlProps } from 'react-bootstrap';
import { useSearchParams } from 'react-router-dom';

export interface SearchBoxProps extends FormControlProps {
  placeholder?: string;
  className?: string;
  inputClassName?: string;
  formClassName?: string;
  size?: 'sm' | 'lg';
  style?: CSSProperties;
}

const SearchBox = ({
  placeholder = 'Search',
  size,
  className,
  inputClassName,
  formClassName,
  style,
  ...rest
}: SearchBoxProps) => {
  const [searchParams, setSearchParams] = useSearchParams({
    [SearchParamsKey.Search]: ''
  });
  const setSearchTransactionsValue = useTransactionsSearchStore.use.setSearch();
  // const currentStart = searchParams.get(SearchParamsKey.StartDate);
  // const currentEnd = searchParams.get(SearchParamsKey.EndDate);

  // const input = document.getElementById('search-input');

  const handleClear = () => {
    setSearchTransactionsValue('');
    searchParams.delete(SearchParamsKey.Search);
    setSearchParams(searchParams);
    // const url = new URL(window.location.toString());
    // url.searchParams.delete('search');
    // window.history.pushState({}, '', url);
  };

  return (
    <div className={classNames('search-box', className)} style={style}>
      <form
        className={classNames(
          'position-relative d-flex justify-content-end',
          formClassName
        )}
      >
        <Form.Control
          id="search-input"
          type="search"
          placeholder={placeholder}
          className={classNames('search-input search', inputClassName)}
          size={size}
          {...rest}
        />
        <FontAwesomeIcon icon={faSearch} className="search-box-icon" />
        {searchParams.get(SearchParamsKey.Search) && (
          <Button className="position-absolute" onClick={handleClear}>
            <FontAwesomeIcon className="position-absolute" icon={faClose} />
          </Button>
        )}
      </form>
    </div>
  );
};

export default SearchBox;
