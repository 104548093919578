import { useState } from 'react';
// import { Navigate } from 'react-router-dom';
// import { api } from 'config/axios';
import { Container } from 'react-bootstrap';
import Button from 'components/base/Button';
// import { useSignOut } from 'hooks/useSignOut';
import TransactionsSearchFilter from '../transactions-search-filter/transactions-search-filter';
import { TransactionsDatePicker } from '../transactions-date-picker';
// import useNavigateUser from 'hooks/useNavigateUser';
import dayjs from 'dayjs';
import { SearchParamsKey } from 'enums';
import { useSearchParams } from 'react-router-dom';
import { useGlobalStore } from 'global-store';

const TransactionsHeader = () => {
  const [searchParams, setSearchParams] = useSearchParams({
    [SearchParamsKey.StartDate]: dayjs(new Date()).format('YYYY-MM-DD'),
    [SearchParamsKey.EndDate]: dayjs(new Date()).format('YYYY-MM-DD')
  });
  const [currentFromDate, setCurrentFromDate] = useState(
    searchParams.get(SearchParamsKey.StartDate)
  );
  const [currentTillDate, setCurrentTillDate] = useState(
    searchParams.get(SearchParamsKey.EndDate)
  );

  // const apiUrl = 'https://testpaspay-guard.paspay.kz';
  // const apiUrl = `${process.env.REACT_APP_API_TESTGUARD}`;
  // const navigateToAuth = useNavigateUser('/auth/sign-in');

  // const [error, setError] = useState('');

  // async function fetchBalance() {
  //   try {
  //     const { data } = await api.get(`${apiUrl}/cabinet/get-balance`);
  //     setBalance(data);
  //   } catch (err) {
  //     if (!err.response) {
  //       localStorage.removeItem('accessToken');
  //       localStorage.removeItem('isUserValid');
  //       console.log('Unauthorized...');
  //       setError('unauthorized');
  //     } else {
  //       console.error('err with response: ', error);
  //       setError(true);
  //     }
  //   }
  // }

  const setDate = useGlobalStore.use.setTransactionsDate();

  const handleSetDate = (start, end) => {
    const startDate = dayjs(start).format('YYYY-MM-DD');
    const endDate = dayjs(end).format('YYYY-MM-DD');

    searchParams.set(SearchParamsKey.StartDate, startDate);
    searchParams.set(SearchParamsKey.EndDate, endDate);
    searchParams.delete(SearchParamsKey.PageIndex);

    setDate({ startDate, endDate });
    setSearchParams(searchParams);
  };

  // useEffect(() => {
  //   fetchBalance();
  // }, []);

  // console.log('render');

  // if (error === 'unauthorized') {
  //   useSignOut(navigateToAuth);
  // }

  return (
    <>
      <Container className="d-flex flex-column">
        {/* <Container className="p-3 py-4 mx-2 mb-4 border rounded">
          <h4>
            <span className="fs-7">Баланс счёта:</span>{' '}
            <span>{balance ? balance : ' Баланс не загружен'}</span>
          </h4>
        </Container> */}
        <div className="d-flex align-items-center justify-content-between my-5 items-center">
          <div className="d-flex align-items-end">
            <TransactionsSearchFilter />
          </div>
          <div className="d-flex gap-3 h-auto flex-row">
            <TransactionsDatePicker
              date={currentFromDate}
              setDate={setCurrentFromDate}
              label="Дата от"
              maxDate={new Date()}
            />
            <TransactionsDatePicker
              date={currentTillDate}
              setDate={setCurrentTillDate}
              label="Дата до"
              maxDate={new Date()}
              minDate={currentFromDate}
            />
            <Button
              style={{
                backgroundColor: '#31374A',
                padding: '10px 16px',
                maxHeight: '3rem'
              }}
              variant="primary"
              onClick={() => handleSetDate(currentFromDate, currentTillDate)}
            >
              Применить фильтр
            </Button>
          </div>
        </div>
        {/* <Container className="text-center">
          <h4 className="mb-1 fw-lighter">История транзакций</h4>
        </Container> */}
      </Container>
    </>
  );
};

export default TransactionsHeader;
