import axios from 'axios';
import { SecureLocalStorageHelper } from 'local-storage';
import qs from 'qs';

const api = axios.create({
  headers: {
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Request-Methods': ['GET', 'POST'],
    'Accept-Language': ['en-us', 'ru-Ru']
  }
});

const LOGIN_URL = 'https://auth.paspay.kz/oauth/token';
const REFRESH_URL = 'https://auth.paspay.kz/oauth2/token';

api.interceptors.request.use(config => {
  console.log('request intercept');
  const CLIENT_ID = 'stp_paspay_web';
  const CLIENT_SECRET = 'XfvfFp7FDT';

  const isLoginRequest = config.url === LOGIN_URL;

  if (isLoginRequest) {
    config.headers.Authorization =
      'Basic ' + window.btoa(`${CLIENT_ID}:${CLIENT_SECRET}`);
    config.headers['Content-Type'] = 'multipart/form-data';
  } else {
    const accessToken = SecureLocalStorageHelper.getBearerToken();
    if (accessToken) {
      config.headers.Authorization = 'Bearer ' + accessToken;
    }
    config.headers['Content-Type'] = 'application/json';
  }

  return config;
});

// Response interceptor to handle token refresh
api.interceptors.response.use(
  response => {
    // Возвращаем успешный ответ, если всё в порядке
    return response;
  },
  async error => {
    const originalRequest = error.config;
    console.log('RESPONSE intercept');

    // Проверяем, истёк ли accessToken (например, ошибка 401)
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      const refreshToken = SecureLocalStorageHelper.getRefreshToken(); // Динамически получаем refresh_token
      if (!refreshToken) {
        // Если нет refreshToken, перенаправляем на страницу логина или другое действие
        return Promise.reject(error);
      }

      try {
        // Создаём данные запроса для обновления токенов динамически
        const data = {
          grant_type: 'refresh_token',
          refresh_token: refreshToken, // Получаем актуальный refresh_token
          client_id: 'paspay-cabinet-web-client',
          client_secret:
            'CUEbd9WjPqRXPW8SDt0GjikKTr5R45mZykJ5LIujl7P5sIGG5ZzBL4FXmqmBzoJQ'
        };

        const response = await axios.post(REFRESH_URL, qs.stringify(data), {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          }
        });

        // Сохраняем новый accessToken и refreshToken
        console.log('refresh tokens successfully...');
        const newAccessToken = response.data.access_token;
        const newRefreshToken = response.data.refresh_token;
        SecureLocalStorageHelper.setBearerToken(newAccessToken);
        SecureLocalStorageHelper.setRefreshToken(newRefreshToken);

        // Повторяем исходный запрос с новым токеном
        originalRequest.headers.Authorization = 'Bearer ' + newAccessToken;
        return api(originalRequest);
      } catch (err) {
        // Если обновление токена не удалось, выполняем нужные действия
        console.log('Unsuccessful tokens update...');
        return Promise.reject(err);
      }
    }

    // Если ошибка другая, возвращаем её как есть
    return Promise.reject(error);
  }
);

export default api;
