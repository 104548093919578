import {
  faChevronLeft,
  faChevronRight
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import usePagination from 'hooks/usePagination';
import { Col, Pagination, Row } from 'react-bootstrap';
import useTransactionsPaginationStore from './store/store';
import useTransactionsSearchStore from '../transactions-search-filter/store/store';
import { useLoaderData, useSearchParams } from 'react-router-dom';
import { SearchParamsKey } from 'enums';
import { TransactionsListResponseType } from '../types';

const TransactionsFooter = () => {
  const [searchParams, setSearchParams] = useSearchParams({
    [SearchParamsKey.Search]: '',
    [SearchParamsKey.PageIndex]: '0'
  });
  const setSearchValue = useTransactionsSearchStore.use.setSearch();
  const currentPage = useTransactionsPaginationStore.getState().pageIndex;
  const setCurrentPage = useTransactionsPaginationStore.use.setPageIndex();

  const { total_pages } = useLoaderData() as TransactionsListResponseType;

  const { hasPrevEllipsis, hasNextEllipsis, visiblePaginationItems } =
    usePagination({
      currentPageNo: currentPage,
      totalPage: total_pages,
      maxPaginationButtonCount: 5
    });

  const goToPage = (page: number) => {
    setCurrentPage(page);
    setSearchValue('');
    searchParams.delete(SearchParamsKey.Search);
    searchParams.set(SearchParamsKey.PageIndex, page.toString());
    setSearchParams(searchParams);
  };

  return (
    <Row className="align-items-center py-1">
      <Col className="d-flex fs-9">
        {/* <p className="mb-0 d-none d-sm-block me-3 fw-semibold text-body">
            {10 * currentPageIndex + 1} to {10 * (currentPageIndex + 1)}
            <span className="text-body-tertiary"> items of </span>
            {totalItems}
          </p> */}
      </Col>
      <Col xs="auto">
        {total_pages > 0 && (
          <Pagination className="mb-0 justify-content-center">
            <Pagination.Prev
              disabled={currentPage === 0}
              onClick={() => {
                goToPage(currentPage - 1);
              }}
            >
              <FontAwesomeIcon icon={faChevronLeft} />
            </Pagination.Prev>

            {hasPrevEllipsis && (
              <>
                <Pagination.Item
                  active={currentPage === 0}
                  onClick={() => {
                    goToPage(0);
                  }}
                >
                  1
                </Pagination.Item>
                <Pagination.Ellipsis disabled />
              </>
            )}

            {visiblePaginationItems.map(page => (
              <Pagination.Item
                key={page}
                active={currentPage === page - 1}
                onClick={() => {
                  goToPage(page - 1);
                }}
              >
                {page}
              </Pagination.Item>
            ))}

            {hasNextEllipsis && (
              <>
                <Pagination.Ellipsis disabled />
                <Pagination.Item
                  active={currentPage === total_pages - 1}
                  onClick={() => {
                    goToPage(total_pages - 1);
                  }}
                >
                  {total_pages}
                </Pagination.Item>
              </>
            )}
            <Pagination.Next
              disabled={currentPage === total_pages - 1}
              onClick={() => {
                goToPage(currentPage + 1);
              }}
            >
              <FontAwesomeIcon icon={faChevronRight} />
            </Pagination.Next>
          </Pagination>
        )}
      </Col>
    </Row>
  );
};

export default TransactionsFooter;
