import { Navbar } from 'react-bootstrap';
import { NavItems, NavbarBrand } from './components';
import { ReactSVG } from 'react-svg';
import alert from '../../../assets/alert.svg';

const Header = () => {
  const isMessage = false; // ? temporary solution
  const isWarning = false; // ? temporary solution

  return (
    <>
      <Navbar className="navbar-top fixed-top px-0" expand variant="">
        <div className="w-100 h-100">
          {isMessage ? (
            <div className="notification-fixed w-100 navbar-top d-flex align-items-center align-content-center gap-2 justify-content-center">
              <ReactSVG src={alert} />
              <b>Плановые технические работы </b>
              10 октября с 12:00 до 18:00 будут проводиться плановые технические
              работы на сервере. В этот период могут быть недоступны личный
              кабинет и раздел платежей.
            </div>
          ) : (
            isWarning && (
              <div className="notification-fixed warning w-100 navbar-top d-flex align-items-center align-content-center gap-2 justify-content-center">
                <ReactSVG src={alert} />
                <b>Внимание! </b>В связи с техническими работам ожидается полная
                недоступность системы в период с 19:00-20:00.
              </div>
            )
          )}
          <div
            className={`${
              (isMessage || isWarning) && 'my-2'
            } pe-6 ps-4 navbar-collapse justify-content-between`}
          >
            <NavbarBrand />

            <NavItems />
          </div>
        </div>
      </Navbar>
    </>
  );
};

export default Header;
