import { createSelectors } from 'config/zustand';
import { TransactionsType } from 'modules/transactions/types';
import { create } from 'zustand';

type State = {
  search: string;
  filteredData: TransactionsType[];
};

const initialState: State = {
  search: '',
  filteredData: []
};

type Action = {
  setFilteredData: (data: TransactionsType[]) => void;
  setSearch: (pageIndex: State['search']) => void;
  reset: () => void;
};

const useTransactionsSearchBase = create<State & Action>(set => ({
  search: initialState['search'],
  filteredData: initialState['filteredData'],
  setFilteredData: data => set(() => ({ filteredData: data })),
  setSearch: search => set(() => ({ search: search })),
  reset: () => set(initialState)
}));

const useTransactionsSearchStore = createSelectors(useTransactionsSearchBase);

export default useTransactionsSearchStore;
