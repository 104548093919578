export enum WeekDay {
  Sunday = 0,
  Monday = 1,
  Tuesday = 2,
  Wednesday = 3,
  Thursday = 4,
  Friday = 5,
  Saturday = 6
}

export enum SearchParamsKey {
  PageIndex = 'pageIndex',
  StartDate = 'startDate',
  EndDate = 'endDate',
  PaymentStatus = 'paymentStatus',
  Search = 'search'
}
