import { ModalBase } from 'new-components';
import { checkClientPhotoAsync } from '../../api/check-client-photo';
import { useState, useEffect } from 'react';
import useOrdersQrCodeStore from '../store/store';

interface Props {
  show: boolean;
  onHide: () => void;
}

export const ShowPhotoModal = ({ show, onHide }: Props) => {
  // const URL =
  //   'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTFxpoAxrZ9q1dQffJz3u3LQGPTi9DwaFk7Bw&s';

  const [loading, setLoading] = useState(false);
  const [photo, setPhoto] = useState('');
  const dataFromConfirmModal = useOrdersQrCodeStore(
    state => state.dataFromConfirmModal
  );

  const submitData = () => {
    checkClientPhotoAsync({ bnpl_id: dataFromConfirmModal?.bnplId })
      .then(res => {
        if (res?.data.error_code === 0) {
          setPhoto(`data:image/jpeg;base64,${res?.data?.data}`);
        } else {
          // onFailGenerateCode();
        }
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (show) {
      setLoading(true);
      submitData();
    }
    if (!show) {
      setLoading(false);
    }
  }, [show]);

  return (
    // <ModalBase title="Фото" titleCentered show={show} onHide={onHide}>
    //   <div
    //     className="mb-3"
    //     style={{
    //       color: '#31374A',
    //       fontSize: '14px',
    //       lineHeight: '18px',
    //       fontWeight: 700
    //     }}
    //   >
    //     Noname Noname
    //   </div>
    //   <img width={'330px'} height={'330px'} src={URL} alt="user-photo" />
    // </ModalBase>
    <ModalBase title="Фото" titleCentered show={show} onHide={onHide}>
      <div
        className="mb-3"
        style={{
          color: '#31374A',
          fontSize: '14px',
          lineHeight: '18px',
          fontWeight: 700
        }}
      >
        {dataFromConfirmModal?.buyer}
      </div>
      {loading ? (
        <p>Loading...</p>
      ) : (
        photo && (
          <img
            style={{ objectFit: 'cover' }}
            src={photo}
            width={'330px'}
            height={'330px'}
            alt="user-photo"
          />
        )
      )}
    </ModalBase>
  );
};
