import { formatSecondsToHHMMSS } from 'helpers';
import { useTimer } from 'hooks/useTimer';
import { HTMLProps, useEffect } from 'react';

interface Props extends HTMLProps<HTMLDivElement> {
  time: number;
}

export const Timer = ({ time, ...rest }: Props) => {
  const { start, stop, time: seconds } = useTimer(time);

  useEffect(() => {
    start(time);

    return () => {
      stop();
    };
  }, []);

  return (
    <div
      {...rest}
      className="d-inline-flex"
      style={{
        backgroundColor: '#C7EBFF',
        padding: '2.5px 10px',
        borderRadius: '4px',
        border: '1px solid #96D9FF',
        color: '#005585',
        fontSize: '16px',
        fontWeight: 700,
        ...rest.style
      }}
    >
      {formatSecondsToHHMMSS(seconds)}
    </div>
  );
};
