import { create } from 'zustand';

interface DataFromConfirmModal {
  bnplId: number;
  orderNumber: string;
  date: string;
  amount: number;
  paymentCount: number;
  manager: string;
  buyer: string;
}

interface UserState {
  clientPhoto: string;
  tempBnplId: number;
  openConnection: boolean;
  dataFromConfirmModal: DataFromConfirmModal | null;
  setTempBnplId: (id: number) => void;
  setOpenConnection: (open: boolean) => void;
  setDataFromConfirmModal: (data: DataFromConfirmModal) => void;
  setClientPhot: (photo: string) => void;
}

const useCreateBillStore = create<UserState>(set => ({
  clientPhoto: '',
  tempBnplId: 0,
  openConnection: false,
  dataFromConfirmModal: null,
  setClientPhot: (photo: string) => set({ clientPhoto: photo }),
  setTempBnplId: (id: number) => set({ tempBnplId: id }),
  setOpenConnection: (open: boolean) => set({ openConnection: open }),
  setDataFromConfirmModal: (data: DataFromConfirmModal) =>
    set({ dataFromConfirmModal: data })
}));

export default useCreateBillStore;
