import { faCheck, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Timeline from 'components/base/Timeline';
import { Col, Row } from 'react-bootstrap';
import { formatDate, separateThousands } from '../../helpers/new-utils';

interface PaymentDetailHistory {
  status: number;
  paymentDate: string;
  paymentSum: number;
}

interface ActivityTimelineProps {
  data: PaymentDetailHistory[];
}

const ActivityTimeline = ({ data }: ActivityTimelineProps) => {
  // function getOverdueDay(date: string) {
  //   const now = new Date();
  //   const targetDate = new Date(date);
  //   const differenceInMilliseconds = now - targetDate;
  //   const millisecondsPerDay = 24 * 60 * 60 * 1000;
  //   const differenceInDays = Math.floor(
  //     differenceInMilliseconds / millisecondsPerDay
  //   );
  //   return differenceInDays;
  // }

  // function getLastDigit(number: number) {
  //   return Math.abs(number) % 10;
  // }

  if (!data || data.length === 0) {
    return <p>Нет доступных данных.</p>;
  }

  return (
    <Timeline variant="vertical">
      {data.map((item, index) => {
        return (
          <Timeline.Item className="position-relative" key={index}>
            <Row className="g-md-3 mt-1">
              <Col xs={12} md="auto" className="d-flex">
                <Timeline.Separator className="position-relative">
                  <Timeline.Dot
                    className={`bg-${
                      item.status === 1
                        ? 'secondary'
                        : item.status === 2
                        ? 'success'
                        : 'danger'
                    }`}
                  >
                    <FontAwesomeIcon
                      icon={
                        item.status === 1
                          ? faCheck
                          : item.status === 2
                          ? faCheck
                          : faXmark
                      }
                      className="text-light fs-10"
                    />
                  </Timeline.Dot>
                  {index !== data.length - 1 && (
                    <Timeline.Bar
                      style={{ right: '45%' }}
                      className="h-75 border-dashed"
                    />
                  )}
                </Timeline.Separator>
                <Timeline.OppositeContent className="mx-3">
                  <h5 className="fs-9 fw-semibold text-body-tertiary text-opacity-85 text-start">
                    {formatDate(new Date(item.paymentDate))}
                  </h5>
                  <h5 className="fs-8 fw-semibold text-body-tertiary">
                    {separateThousands(item.paymentSum)} ₸
                  </h5>
                </Timeline.OppositeContent>
              </Col>
              <Col className="ps-9">
                <Timeline.Content>
                  {item.status === 1 ? (
                    <>
                      <h5 className="fs-14 fw-semibold text-start ff-inter">
                        Ожидает списание
                      </h5>
                      {/* <h6
                        className={classNames('text-s ff-inter fs-14', {
                          'mb-3': index !== data.length - 1,
                          'mb-0': index === data.length - 1
                        })}
                      >
                        День оплаты
                      </h6> */}
                    </>
                  ) : item.status === 2 ? (
                    <>
                      <h5 className="fs-14 fw-semibold text-start text-success ff-inter">
                        Успешная оплата
                      </h5>
                    </>
                  ) : (
                    <>
                      <h5 className="fs-14 fw-semibold text-start ff-inter">
                        Неудачное списание
                      </h5>
                      {/* <h6
                        className={classNames('text-s ff-inter fs-14', {
                          'mb-3': index !== data.length - 1,
                          'mb-0': index === data.length - 1
                        })}
                      >
                        {getOverdueDay(item.paymentDate)}{' '}
                        {getLastDigit(getOverdueDay(item.paymentDate)) === 1
                          ? 'день '
                          : getLastDigit(getOverdueDay(item.paymentDate)) >=
                              2 &&
                            getLastDigit(getOverdueDay(item.paymentDate)) <= 4
                          ? 'дня '
                          : 'дней '}{' '}
                        просрочки
                      </h6> */}
                    </>
                  )}
                </Timeline.Content>
              </Col>
            </Row>
          </Timeline.Item>
        );
      })}
    </Timeline>
  );
};

export default ActivityTimeline;
