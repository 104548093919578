import { create } from 'zustand';
import { UserType } from './types';
import { createSelectors } from 'config/zustand';

type State = {
  user: UserType | null;
  isUserValid: boolean;
};

const initialState: State = {
  user: null,
  isUserValid: localStorage.getItem('isUserValid') === 'true' ? true : false
};

type Action = {
  setUser: (user: State['user']) => void;
  setIsUserValid: (arg: boolean) => void;
  reset: () => void;
};

const useUserStoreBase = create<State & Action>(set => ({
  user: null,
  isUserValid: initialState['isUserValid'],
  setIsUserValid: (arg: boolean) => {
    set({ isUserValid: arg });
    localStorage.setItem('isUserValid', arg ? 'true' : 'false');
  },
  setUser: user => set(() => ({ user: user })),
  reset: () => {
    set(initialState);
  }
}));

const useUserStore = createSelectors(useUserStoreBase);

export default useUserStore;
