import { SecureLocalStorageHelper } from 'local-storage';
import { redirect } from 'react-router-dom';

export const loader = () => {
  const isAuth = SecureLocalStorageHelper.hasBearerToken();
  if (isAuth) {
    return redirect('/');
  }

  return null;
};
