import clock from '../../assets/clock.svg';

export const Loader = () => (
  <div
    className="d-flex justify-content-center align-items-center"
    style={{
      width: '600px',
      height: '400px',
      borderRadius: '12px'
    }}
  >
    <div className="text-center">
      <div
        className="d-inline-flex mb-3"
        style={{
          backgroundColor: '#E3E6ED',
          padding: '12px 11px 12px 12px',
          borderRadius: '12px'
        }}
      >
        <img src={clock} alt="clock" />
      </div>
      <div>
        <h3>Идет загрузка</h3>
        <p>Пожалуйста подождите</p>
      </div>
    </div>
  </div>
);
