import ThemeToggler from 'components/common/ThemeToggler';
import { Nav } from 'react-bootstrap';
import { CreateBill } from 'modules/installment';
import { AboutMeDropdown } from 'modules/authorization';
import { NotificationSidebar } from 'components/notifications/NotificationSidebar';

const NavItems = () => (
  <div className="navbar-nav navbar-nav-icons flex-row">
    <Nav.Item className="me-4">
      <CreateBill />
    </Nav.Item>
    <Nav.Item className="me-3">
      <NotificationSidebar />
    </Nav.Item>
    <Nav.Item className="me-2">
      <ThemeToggler />
    </Nav.Item>
    <Nav.Item>
      <AboutMeDropdown />
    </Nav.Item>
  </div>
);

export default NavItems;
