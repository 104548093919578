import { ColumnDef } from '@tanstack/react-table';
import { PaymentHistoryType } from './types';
import { formatDate, separateThousands } from 'helpers';

export const paymentsHistoryTableColumns: ColumnDef<PaymentHistoryType>[] = [
  {
    accessorKey: 'orderNumber',
    header: '№ заказа Paspay'
  },
  {
    accessorKey: 'merchantOrderNumber',
    header: '№ заказа мерчанта'
  },
  {
    accessorKey: 'operationDate',
    header: 'Дата операции',
    cell: cell => {
      let value = new Date(cell.getValue() as string);
      if (cell.getValue() === '') {
        value = new Date();
      }
      return <>{formatDate(value)}</>;
    }
  },
  {
    accessorKey: 'totalSum',
    header: 'Общая сумма рассрочки',
    cell: cell => {
      const totalSum = parseInt(cell.getValue() as string);
      return <>{separateThousands(totalSum) + ' ₸'}</>;
    }
  },
  {
    accessorKey: 'period',
    header: 'Период',
    cell: cell => {
      const value = cell.getValue() as number | null;
      if (!value) {
        return '';
      }
      return value + ' мес';
    }
  },
  {
    accessorKey: 'contacts',
    header: 'Контакты',
    enableSorting: false,
    cell: cell => {
      const value = cell.getValue() as {
        fullName: string;
        phoneNumber: string;
      };
      return (
        <div>
          <div>{value.fullName}</div>
          <div>{value.phoneNumber}</div>
        </div>
      );
    }
  },
  {
    accessorKey: 'paymentDate',
    header: 'Дата оплаты',
    enableSorting: false,
    cell: cell => {
      return <>{formatDate(new Date(cell.getValue() as string))}</>;
    }
  },
  {
    accessorKey: 'paidPeriods',
    header: 'Оплаченные периоды',
    enableSorting: false,
    cell: cell => {
      const value = cell.getValue() as number | null;
      if (!value) {
        return '0 /0';
      }
      return `${value} / ${cell.row.original.period} мес`;
    }
  },
  {
    accessorKey: 'paymentSum',
    header: 'Сумма оплаты',
    cell: cell => {
      const value = cell.getValue() as {
        sum: number;
        type: string;
      };
      return (
        <div>
          <div>{separateThousands(value.sum)} ₸</div>
        </div>
      );
    }
  }
];
