import { useState } from 'react';
import { PaymentDetailsDrawer } from './components/drawer';
import { PaymentHistoryType, PaymentsHistoryTable } from './components/table';
import {
  PaymentDetailsResponseType,
  getPaymentsDetails
} from '../api/payments-detail';
import { PaymentDetail } from './components/drawer/drawer';
import { DateFormatter } from 'helpers';
import { useLoaderData } from 'react-router-dom';
import { formatPhone } from '../../../helpers/utils';

const formatResponse = (
  res: PaymentDetailsResponseType['data']
): PaymentDetail => ({
  paspayOrderNumber: res?.bnplId,
  orderNumber: res?.orderId,
  operationDate: DateFormatter(res?.createdAt),
  totalSum: res?.amount,
  period: res?.paymentCount?.toString() ?? '',
  borrower: {
    fullName: res?.borrower,
    phoneNumber: formatPhone(res?.borrower_phone)
  },
  trusteeList: res?.trustees?.map(item => ({
    fullName: item.nameSurname,
    phoneNumber: formatPhone(item.phoneNumber)
  })),
  paymentHistory: res?.paymentHistory?.map(item => ({
    status: item.status,
    paymentDate: item.payment_date,
    paymentSum: item.payment_sum
  })),
  monthPaymentSum: Number(res?.singlePaymentAmount),
  paidPeriods: res?.successPaymentNumber.toString() ?? ''
});

const PaymentsHistoryList = () => {
  const { paymentsHistories } = useLoaderData() as {
    totalPages: number;
    paymentsHistories: PaymentHistoryType[];
  };
  const [showDrawer, setShowDrawer] = useState(false);
  const [selectedPaymentDetail, setSelectedPaymentDetail] =
    useState<PaymentDetail | null>(null);

  const handleItemClick = (id: number) => {
    setShowDrawer(true);
    getPaymentsDetails(id)
      .then(res => {
        if (res?.data.data) {
          console.log('res.data:::', res.data);
          const paymentDetail = formatResponse(res?.data.data);
          setSelectedPaymentDetail(paymentDetail);
          console.log(selectedPaymentDetail);
        }
      })
      .catch(err => {
        throw new Error(err);
      });
  };

  const handleHide = () => {
    setShowDrawer(false);
    setSelectedPaymentDetail(null);
  };

  const handleClose = () => {
    setShowDrawer(false);
    setSelectedPaymentDetail(null);
  };

  return (
    <>
      {paymentsHistories && paymentsHistories.length === 0 ? (
        <h5 className="text-center text-danger">
          Нет транзакций за данный период
        </h5>
      ) : (
        <>
          <PaymentDetailsDrawer
            show={showDrawer}
            detail={selectedPaymentDetail}
            onHide={handleHide}
            onCloseBtnClick={handleClose}
          />
          <PaymentsHistoryTable onItemClick={handleItemClick} />
        </>
      )}
    </>
  );
};

export default PaymentsHistoryList;
