import { faClose } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { ReactNode } from 'react';
import { Modal } from 'react-bootstrap';

interface Props {
  show: boolean;
  title: string;
  children?: ReactNode;
  onHide: () => void;
  titleCentered?: boolean;
}

export const ModalBase = ({
  title,
  children,
  show,
  onHide,
  titleCentered
}: Props) => {
  return (
    <Modal show={show} centered onHide={onHide}>
      <Modal.Header
        style={{ paddingTop: '30px' }}
        className={classNames('d-flex position-relative', {
          'justify-content-center': titleCentered
        })}
      >
        <Modal.Title
          className="modal-base-title"
          style={{
            fontSize: 20,
            fontWeight: 700,
            lineHeight: '24px'
          }}
        >
          {title}
        </Modal.Title>
        <FontAwesomeIcon
          onClick={onHide}
          className="position-absolute"
          icon={faClose}
          style={{ right: '16px', cursor: 'pointer' }}
        />
      </Modal.Header>
      <Modal.Body className="d-flex flex-column align-items-center pb-4">
        {children}
      </Modal.Body>
    </Modal>
  );
};
