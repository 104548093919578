import {
  PaymentsHistoryDatePicker,
  PaymentsHistoryFooter,
  PaymentsHistoryList,
  PaymentsHistorySearchFilter
} from 'modules/payments-history';

const PaymentHistoryPage = () => {
  return (
    <>
      <div className="d-flex justify-content-between mb-4">
        <PaymentsHistorySearchFilter />
        <div className="d-flex">
          <PaymentsHistoryDatePicker />
        </div>
      </div>
      <PaymentsHistoryList />
      <PaymentsHistoryFooter />
    </>
  );
};

export default PaymentHistoryPage;
