export enum PaymentStatus {
  Waiting = '0',
  Success = '1',
  Reject = '2',
  Return = '3',
  Waiting_QR_Scanned = '4',
  Waiting_BNPL_end = '5',
  Waiting_entered_BNPL_code = '6',
  Cancel = '7'
}
