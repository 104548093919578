import { ModalBase } from 'new-components';
import { Spinner } from 'react-bootstrap';
// import { Timer } from 'components/timer/timer';

interface Props {
  show: boolean;
  onHide: () => void;
  time: string;
}

export const CheckingClientModal = ({ show, onHide }: Props) => {
  // console.log('TIME');
  // console.log(time);

  return (
    <ModalBase
      title="Проверка клиента"
      show={show}
      onHide={onHide}
      titleCentered
    >
      <div className="d-flex justify-content-center align-items-center mb-2 w-100">
        <div
          className="d-flex flex-column align-items-center"
          style={{ marginTop: '160px', marginBottom: '94px' }}
        >
          {/* <Timer
            style={{ marginBottom: '77px' }}
            time={formatMMSSToSeconds(time)}
          /> */}
          <Spinner style={{ width: '56px', height: '56px' }} />
          <div
            className="mt-4 text-center"
            style={{ fontSize: '20px', fontWeight: 600, color: '#31374A' }}
          >
            Процесс проверки <br /> клиента
          </div>
        </div>
      </div>
    </ModalBase>
  );
};
