export enum OrderStatus {
  QR_CODE_GENERATION = 0,
  CLIENT_VERIFICATION = 4,
  CODE_CHECKING = 5,
  WAITING_ISSUE = 6,
  SUCCESS = 1,
  REJECT = 2,
  RETURN = 3,
  CANCEL = 7
}

// еще не отсканирован код 0
// отсканирован код 4
// проверка кода 5
// выдат заказ 6
