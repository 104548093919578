import { queryClient } from 'index';
import { SecureLocalStorageHelper } from 'local-storage';

export const useSignOut = () => {
  // const redirectUri = 'http://localhost:5173/auth/sign-in';
  const redirectUri = 'https://cabinet.paspay.kz/auth/sign-in';
  queryClient.removeQueries({ queryKey: ['aboutMeData'], exact: true });
  queryClient.clear();
  SecureLocalStorageHelper.clearAll();
  localStorage.removeItem('isUserValid');
  window.location.href = `https://auth.paspay.kz/logout?redirect_uri=${redirectUri}`;
  console.log('navigate');
};
