import classNames from 'classnames';
import Button from 'components/base/Button';
import CustomDateRangePicker, {
  Props as CustomDateRangePickerProps
} from 'components/date-range-picker/component';
import dayjs from 'dayjs';
import { useAppContext } from 'providers/AppProvider';
import { useState } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, Form } from 'react-bootstrap';

interface Props extends CustomDateRangePickerProps {
  className?: string;
}

export const DropdownPicker = (props: Props) => {
  const {
    config: { theme }
  } = useAppContext();

  const [visible, setVisible] = useState(false);
  const [range, setRange] = useState(props.range);

  const handleOnToggle = (nextShow: boolean) => {
    if (nextShow) {
      setRange(props.range);
    }
    setVisible(nextShow);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const handleApplyFilter = () => {
    props.setRange(range);
    setVisible(false);
  };

  const fromDate = dayjs(props.range.startDate).format('DD.MM.YYYY');
  const toDate = dayjs(props.range.endDate).format('DD.MM.YYYY');

  return (
    <Dropdown
      className={props.className}
      show={visible}
      onToggle={handleOnToggle}
      autoClose={true}
    >
      <DropdownToggle className="dropdown-caret-none py-0 px-0 bg-white">
        <form className={classNames('position-relative')}>
          <Form.Control
            value={`${fromDate} - ${toDate}`}
            readOnly
            className="dropdown-control"
          />
        </form>
      </DropdownToggle>
      <DropdownMenu className={`${theme == 'dark' && `drop-down-dark`}`}>
        {visible && (
          <div className="d-flex flex-column">
            <CustomDateRangePicker range={range} setRange={setRange} />
            <div className="d-flex justify-content-between rdrDateRangePickerButtons">
              <Button onClick={handleCancel} className="dp-btn-cancel">
                Отменить
              </Button>
              <Button
                variant="secondary"
                onClick={handleApplyFilter}
                className="dp-btn-confirm"
              >
                Применить фильтр
              </Button>
            </div>
          </div>
        )}
      </DropdownMenu>
    </Dropdown>
  );
};
