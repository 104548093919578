import { faEye, faEyeSlash, faKey } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'components/base/Button';
import { useState } from 'react';
import { Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const ResetPasswordForm = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [showRepeatPassword, setShowRepeatPassword] = useState(false);

  const handlePasswordToggle = () => {
    setShowPassword(!showPassword);
  };

  const handleRepeatPasswordToggle = () => {
    setShowRepeatPassword(!showRepeatPassword);
  };

  return (
    <div className="text-center mb-6">
      <h4 className="text-body-highlight">Восстановление пароля</h4>
      <p className="text-body-tertiary">
        Получите доступ к своей учётной записи
      </p>
      <Form.Group className="mb-3 text-start">
        <Form.Label htmlFor="password">Пароль</Form.Label>
        <div className="d-flex justify-content-end form-icon-container">
          <Form.Control
            id="password"
            type={showPassword ? 'text' : 'password'}
            className="form-icon-input"
            placeholder="Пароль"
          />
          <FontAwesomeIcon icon={faKey} className="text-body fs-9 form-icon" />
          <Button className="position-absolute" onClick={handlePasswordToggle}>
            <FontAwesomeIcon
              icon={showPassword ? faEyeSlash : faEye}
              className="position-absolute"
            />
          </Button>
        </div>
      </Form.Group>
      <Form.Group className="mb-3 text-start">
        <Form.Label htmlFor="password">Повторить пароль</Form.Label>
        <div className="d-flex justify-content-end form-icon-container">
          <Form.Control
            id="repeat-password"
            type={showRepeatPassword ? 'text' : 'password'}
            className="form-icon-input"
            placeholder="Повторить пароль"
          />
          <FontAwesomeIcon icon={faKey} className="text-body fs-9 form-icon" />
          <Button
            className="position-absolute"
            onClick={handleRepeatPasswordToggle}
          >
            <FontAwesomeIcon
              icon={showRepeatPassword ? faEyeSlash : faEye}
              className="position-absolute"
            />
          </Button>
        </div>
      </Form.Group>
      <Link to="/auth/sign-in">
        <Button variant="secondary" className="w-100">
          Сохранить
        </Button>
      </Link>
    </div>
  );
};

export default ResetPasswordForm;
