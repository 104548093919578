import { api } from 'config/axios';

export const getOrderPhotoAsync = async (id: number) => {
  // const URL = 'https://testpaspay-guard.paspay.kz/bnpl/show-photo';
  const URL = `${process.env.REACT_APP_API_TESTGUARD}/bnpl/show-photo`;

  try {
    const res = await api.post(URL, { bnpl_id: id });
    return res;
  } catch (err) {
    console.log(err);
  }
};
