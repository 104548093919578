import Button from 'components/base/Button';
import { ModalBase } from 'new-components';
import { Col, Row } from 'react-bootstrap';
import { OrderDetail } from '../../types';
import { DateFormatter, separateThousands } from 'helpers';

type Props = {
  show: boolean;
  onHide: () => void;
  order: OrderDetail;
  onMakeReturn: () => void;
};

export const OrderDetailModal = ({
  show,
  onHide,
  order,
  onMakeReturn
}: Props) => {
  return (
    <ModalBase
      title={`Номер заказа: ${order.orderNumber}`}
      show={show}
      onHide={onHide}
    >
      <div className="w-100">
        <Row style={{ marginBottom: '18px' }}>
          <Col>
            <h5 className="text-body-emphasis">Сумма</h5>
          </Col>
          <Col className="text-end">
            <h5 className="text-body-emphasis">
              {separateThousands(order.amount)} ₸
            </h5>
          </Col>
        </Row>
        <Row style={{ marginBottom: '18px' }}>
          <Col>
            <h5 className="text-body-emphasis">Дата операции</h5>
          </Col>
          <Col className="text-end">
            <h5 className="text-body-tertiary">
              {DateFormatter(order.operationDate)}
            </h5>
          </Col>
        </Row>
        <Row style={{ marginBottom: '18px' }}>
          <Col>
            <h5 className="text-body-emphasis">Период</h5>
          </Col>
          <Col className="text-end">
            <h5 className="text-body-emphasis">
              {order.period} {order.period === 3 ? 'месяца' : 'месяцев'}
            </h5>
          </Col>
        </Row>
        <Row style={{ marginBottom: '18px' }}>
          <Col>
            <h5 className="text-body-emphasis">Тип платежа</h5>
          </Col>
          <Col className="text-end">
            <h5 className="text-body-emphasis">
              {Number(order?.paymentType) === 0 ? 'Рассрочка' : 0}
            </h5>
          </Col>
        </Row>
        <Row style={{ marginBottom: '18px' }}>
          <Col>
            <h5 className="text-body-emphasis">Покупатель</h5>
          </Col>
          <Col className="text-end">
            <h5 className="text-body-emphasis">{order.buyer}</h5>
          </Col>
        </Row>
        <Row style={{ marginBottom: '18px' }}>
          <Col>
            <h5 className="text-body-emphasis">Менеджер</h5>
          </Col>
          <Col className="text-end">
            <h5 className="text-body-emphasis">{order.manager}</h5>
          </Col>
        </Row>
      </div>

      <div className="d-flex w-100 justify-content-end">
        <Button variant="outline-danger" onClick={onMakeReturn}>
          Оформить возврат
        </Button>
      </div>
    </ModalBase>
  );
};
