import Button from 'components/base/Button';
import { ModalBase } from 'new-components';
import { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { useSearchParams } from 'react-router-dom';
import { useOrdersFilterStore } from '../store';
import { PaymentStatus } from '../types';
import { SearchParamsKey } from 'enums';
import _ from 'lodash';

interface Props {
  show: boolean;
  onHide: () => void;
  onCancelBtnClick: () => void;
  onApplyBtnClick: () => void;
}

type InitialSearchParamsType = {
  [SearchParamsKey.PaymentStatus]: PaymentStatus[];
};

const initialSearchParams: InitialSearchParamsType = {
  [SearchParamsKey.PaymentStatus]: []
};

export const FilterModal = ({
  show,
  onHide,
  onCancelBtnClick,
  onApplyBtnClick
}: Props) => {
  const [searchParams, setSearchParams] = useSearchParams(initialSearchParams);

  const urlStatus =
    searchParams.getAll(SearchParamsKey.PaymentStatus) ?? undefined;

  console.log('urlStatus: ', urlStatus);

  // const status = useOrdersFilterStore.getState().status;
  // const setStatus = useOrdersFilterStore.use.setStatus();
  // const reset = useOrdersFilterStore.use.reset();
  const { setStatus, reset } = useOrdersFilterStore();

  const [paymentStatus, setPaymentStatus] = useState(() => {
    const statusParams = searchParams
      .getAll(SearchParamsKey.PaymentStatus)
      .map(item => {
        if (item.length > 1) {
          return item.slice(-1);
        } else {
          return item;
        }
      }) as PaymentStatus[];
    setStatus(statusParams);

    const entries = Object.values(PaymentStatus).map(item => [
      item,
      statusParams.includes(item)
    ]);

    const res = Object.fromEntries(entries) as {
      [status in PaymentStatus]: boolean;
    };

    localStorage.setItem('filterParams', JSON.stringify(res));
    console.log(res);
    return res;
  });

  useEffect(() => {
    // if (localStorage.getItem('filterParams') === null) {
    //   return;
    // } else {
    //   //@ts-expect-error str
    //   setPaymentStatus(JSON.parse(localStorage.getItem('filterParams')));
    // }
  }, []);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const statusParams = Object.entries(paymentStatus).reduce(
      (obj: { paymentStatus: PaymentStatus[] }, [key, value]) => {
        if (value) {
          obj.paymentStatus.push(key as PaymentStatus);
        }
        return obj;
      },
      { paymentStatus: [] }
    );

    setStatus(statusParams.paymentStatus);
    console.log('first:', searchParams.get(SearchParamsKey.PaymentStatus));
    setSearchParams(prev => {
      const prevParams = {};
      prev.forEach((value, key) => {
        /* eslint-disable  @typescript-eslint/no-explicit-any */
        (prevParams as any)[key] = value;
      });
      console.log('status setted');

      return { ...prevParams, ...statusParams };
    });
    console.log('second:', searchParams.get(SearchParamsKey.PaymentStatus));

    onApplyBtnClick();
  };

  const handleCancel = () => {
    searchParams.delete(SearchParamsKey.PaymentStatus);
    setPaymentStatus(prev => _.mapValues(prev, () => false));
    reset();
    setSearchParams(searchParams);
    console.log('params setted');
    onCancelBtnClick();
  };

  return (
    <ModalBase title="Фильтр" show={show} onHide={onHide}>
      <Form className="w-100" onSubmit={handleSubmit}>
        <div className="mb-6">
          <div className="mb-3">
            <Form.Group>
              <h5 className="mb-2">По статусу платежа</h5>
              <div className="d-flex flex-column" style={{ padding: '0 12px' }}>
                <div className="d-flex">
                  <Form.Check
                    className="me-5"
                    label="Успешный"
                    name="success"
                    checked={paymentStatus[PaymentStatus.Success]}
                    onChange={e => {
                      setPaymentStatus(prev => ({
                        ...prev,
                        [PaymentStatus.Success]: e.target.checked
                      }));
                    }}
                  />
                  <Form.Check
                    className="me-5"
                    label="В ожидании"
                    name="waiting"
                    checked={paymentStatus[PaymentStatus.Waiting]}
                    onChange={e => {
                      setPaymentStatus(prev => ({
                        ...prev,
                        [PaymentStatus.Waiting]: e.target.checked,
                        [PaymentStatus.Waiting_QR_Scanned]: e.target.checked,
                        [PaymentStatus.Waiting_BNPL_end]: e.target.checked,
                        [PaymentStatus.Waiting_entered_BNPL_code]:
                          e.target.checked
                      }));
                    }}
                  />
                  <Form.Check
                    className="me-5"
                    label="Отменен"
                    name="cancel"
                    checked={paymentStatus[PaymentStatus.Cancel]}
                    onChange={e => {
                      setPaymentStatus(prev => ({
                        ...prev,
                        [PaymentStatus.Cancel]: e.target.checked
                      }));
                    }}
                  />
                  <Form.Check
                    label="Отказ"
                    name="reject"
                    checked={paymentStatus[PaymentStatus.Reject]}
                    onChange={e => {
                      setPaymentStatus(prev => ({
                        ...prev,
                        [PaymentStatus.Reject]: e.target.checked
                      }));
                    }}
                  />
                </div>
                <div className="d-flex">
                  <Form.Check
                    label="Возврат"
                    name="return"
                    checked={paymentStatus[PaymentStatus.Return]}
                    onChange={e => {
                      setPaymentStatus(prev => ({
                        ...prev,
                        [PaymentStatus.Return]: e.target.checked
                      }));
                    }}
                  />
                </div>
              </div>
            </Form.Group>
          </div>
        </div>

        <div className="d-flex justify-content-end">
          <Button onClick={handleCancel}>Отменить</Button>
          <Button variant="secondary" type="submit">
            Применить фильтр
          </Button>
        </div>
      </Form>
    </ModalBase>
  );
};
