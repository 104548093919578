import { ModalBase } from 'new-components';
import { checkClientPhotoAsync } from '../../api/check-client-photo';
import { useState, useEffect } from 'react';
import useCreateBillStore from '../store/store';

interface Props {
  show: boolean;
  onHide: () => void;
}

export const ShowPhotoModal = ({ show, onHide }: Props) => {
  const [loading, setLoading] = useState(false);
  const [photo, setPhoto] = useState('');
  const dataFromConfirmModal = useCreateBillStore(
    state => state.dataFromConfirmModal
  );

  const submitData = () => {
    checkClientPhotoAsync({ bnpl_id: dataFromConfirmModal?.bnplId })
      .then(res => {
        if (res?.data.error_code === 0) {
          setPhoto(`data:image/jpeg;base64,${res?.data?.data}`);
        } else {
          // onFailGenerateCode();
        }
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (show) {
      setLoading(true);
      submitData();
    }
    if (!show) {
      setLoading(false);
    }
  }, [show]);

  return (
    <ModalBase title="Фото" titleCentered show={show} onHide={onHide}>
      <div
        className="mb-3"
        style={{
          color: '#31374A',
          fontSize: '14px',
          lineHeight: '18px',
          fontWeight: 700
        }}
      >
        {dataFromConfirmModal?.buyer}
      </div>
      {/* <img width={'330px'} height={'330px'} src={photo!} alt="user-photo" /> */}
      {loading ? (
        <p>Loading...</p>
      ) : (
        photo && (
          <img
            style={{ objectFit: 'cover' }}
            className="object-fit-cover"
            width={'330px'}
            height={'330px'}
            src={photo}
            alt="user-photo"
          />
        )
      )}
    </ModalBase>
  );
};
