import { AxiosResponse } from 'axios';
import { api } from 'config/axios';

export type CheckClientPhotoRequestParams = {
  bnpl_id?: number;
};

export type CheckClientPhotoResponseType = {
  message: string;
  error_code: number;
  data: string;
};

export const checkClientPhotoAsync = async ({
  bnpl_id
}: CheckClientPhotoRequestParams) => {
  // const URL = 'https://testgateway.paspay.kz/bnpl/show-photo';
  // const URL = `${process.env.REACT_APP_API_TESTGATEWAY}/bnpl/show-photo`;
  const URL = `${process.env.REACT_APP_API_TESTGUARD}/bnpl/show-photo`;

  try {
    const res: AxiosResponse<CheckClientPhotoResponseType> = await api.post(
      URL,
      {
        bnpl_id
      }
    );
    return res;
  } catch (err) {
    console.log(err);
  }
};
