import { useCreateBillStore } from 'modules/installment/create-bill/store';
import { useEffect, useRef, useState } from 'react';

export const useTimer = (initialTime: number) => {
  const [time, setTime] = useState(initialTime);
  const [status, setStatus] = useState<'active' | 'inactive'>('inactive');
  const timerId = useRef<NodeJS.Timeout | null>(null);

  const setOpenConnection = useCreateBillStore(
    state => state.setOpenConnection
  );

  const _startDecrementing = () => {
    // console.log('startDecrementing()');
    timerId.current = setInterval(() => {
      setTime(prev => prev - 1);
    }, 1000);
  };

  useEffect(() => {
    // console.log('effect');
    if (time <= 0) {
      stop();
      setOpenConnection(false);
      console.log('time is 0');
    }
  }, [time]);

  const _stopDecrementing = () => {
    if (timerId.current) {
      clearInterval(timerId.current);
      console.log('clear interval');
    }
  };

  const start = (value?: number) => {
    // console.log('START');
    // console.log(value);
    if (status === 'active') {
      return;
    }
    value && setTime(value);
    if (time === 0) {
      _stopDecrementing();
      // console.log('stopDecrementing()');
      return;
    }

    _startDecrementing();
    setStatus('active');
  };

  const stop = () => {
    _stopDecrementing();
    setStatus('inactive');
    // console.log('STOP');
  };

  const reset = () => {};

  return {
    start,
    stop,
    reset,
    time
  };
};
