import TransactionsHeader from 'modules/transactions/transactions-header/transactions-header';
import TransactionsList from 'modules/transactions/transactions-list/transactions-list';

export const TransactionsPage = () => {
  return (
    <>
      <h2 className="text-body-emphasis" style={{ marginBottom: '62px' }}>
        Транзакции
      </h2>
      <TransactionsHeader />
      <TransactionsList />
    </>
  );
};
