import { redirectDocument } from 'react-router-dom';

export const loader = () => {
  // const isAuth = SecureLocalStorageHelper.hasBearerToken();
  // if (!isAuth) {
  //   useSignOut();
  //   return null;
  // }

  // const isAuth = SecureLocalStorageHelper.hasBearerToken();

  // if (!isAuth) {
  //   return redirect('/auth/sign-in');
  // }

  const pathName = window.location.pathname;

  if (pathName === '/installment' || pathName === '/installment/') {
    return redirectDocument('/installment/orders');
  }

  return null;
};
