import { api } from 'config/axios';
import {
  LoginRequestParams,
  LoginResponseType,
  MerchantInfoResponseType
} from './types';
import { AxiosResponse } from 'axios';

export const loginByBINorIIN = async ({
  bin,
  password
}: LoginRequestParams) => {
  const LOGIN_URL = 'https://auth.paspay.kz/oauth/token';
  try {
    const formData = new FormData();
    formData.append('username', bin);
    formData.append('password', password);
    formData.append('grant_type', 'password');

    const res: AxiosResponse<LoginResponseType> = await api.post(
      LOGIN_URL,
      formData
    );
    return res;
  } catch (err) {
    return Promise.reject(err);
  }
};

export const getAboutMeInfo = async () => {
  // const GET_INFO_URL = 'https://testpaspay-guard.paspay.kz/cabinet/get-info';
  const GET_INFO_URL = `${process.env.REACT_APP_API_TESTGUARD}/cabinet/get-info`;
  const response =
    await api.get<AxiosResponse<MerchantInfoResponseType>>(GET_INFO_URL);
  if (!response.data) {
    throw new Error('No data received');
  }
  return response.data;
};
