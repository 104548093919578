import Button from 'components/base/Button';
import { useState } from 'react';
import { ConfirmCodeModal } from './modals/ConfirmCodeModal';
import { GiveOrderModal } from './modals/GiveOrderModal';
import { ShowPhotoModal } from './modals/ShowPhotoModal';
import test from '../../../assets/question.svg';
import useOrdersQrCodeStore from './store/store';

const OrdersQrCode = () => {
  const [showConfirmCodeModal, setShowConfirmCodeModal] = useState(false);
  const [showGiveOrderModal, setShowGiveOrderModal] = useState(false);
  const [showPhotoModal, setShowPhotoModal] = useState(false);
  const [code, setCode] = useState('');

  const dataFromConfirmModal = useOrdersQrCodeStore(
    state => state.dataFromConfirmModal
  );

  return (
    <>
      <ConfirmCodeModal
        show={showConfirmCodeModal}
        onHide={() => {
          setShowConfirmCodeModal(false);
        }}
        onSuccessGenerateCode={code => {
          setCode(code);
          setShowGiveOrderModal(true);
          setShowConfirmCodeModal(false);
        }}
        onFailGenerateCode={() => {}}
      />

      <GiveOrderModal
        dataFromConfirmModal={dataFromConfirmModal!}
        show={showGiveOrderModal}
        code={code}
        onHide={() => {
          setCode('');
          setShowGiveOrderModal(false);
        }}
        onGiveOrderBtnClick={() => {}}
        onWatchPhotoBtnClick={() => setShowPhotoModal(true)}
      />

      <ShowPhotoModal
        show={showPhotoModal}
        onHide={() => setShowPhotoModal(false)}
      />

      <Button
        className="me-2"
        variant="primary"
        style={{ backgroundColor: '#31374A', padding: '10px 16px' }}
        onClick={() => setShowConfirmCodeModal(true)}
      >
        <img className="me-2" src={test} alt="question" />
        Проверка кода
      </Button>
    </>
  );
};

export default OrdersQrCode;
