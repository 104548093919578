import { formatTime } from 'helpers';
import { Badge, Card } from 'react-bootstrap';

interface Props {
  title: string;
  message: string;
  time: Date;
  isNew: boolean;
}

export const NotificationCard = ({ title, message, time, isNew }: Props) => {
  return (
    <Card className={`${isNew && 'new'} notification-item p-4`}>
      <Card.Title className="fw-bolder d-flex flex-column">
        {isNew && (
          <span>
            <Badge bg="none" className="notification-badge text-black mb-3">
              Новое
            </Badge>
          </span>
        )}
        {title}
      </Card.Title>
      <Card.Body className="p-0 fw-lighter">
        <p>{message}</p>
        <span className="notification-time fw-ligher">
          {formatTime(time.getHours())}:{formatTime(time.getMinutes())}
        </span>
      </Card.Body>
    </Card>
  );
};
