import { ColumnDef } from '@tanstack/react-table';
import { TransactionsType } from '../../../types';
import { formatDateByArray } from 'helpers/utils';
import { separateThousands } from 'helpers';

export const transactionsTableColumns: ColumnDef<TransactionsType>[] = [
  {
    accessorKey: 'transactionType',
    // accessorKey: 'transaction_type',
    header: 'Вид операции',
    enableSorting: false,
    cell: cell => {
      const value = cell.getValue() as string;
      if (value === 'TransactionEmoneyPayment') return 'Прием платежа ЭД';
      if (value === 'TransactionCancelAcquiring') return 'Отмена платежа';
      if (value === 'TransactionRedemption') return 'Вывод средств';
      if (value === 'TransactionWithoutEmoneyAcquiring') {
        return 'Прием платежа без ЭД';
      }
    }
  },
  {
    accessorKey: 'operationId',
    // accessorKey: 'operation_id',
    header: '№ заказа в системе Paspay',
    enableSorting: true
  },
  {
    accessorKey: 'createdAt',
    // accessorKey: 'created_at',
    header: 'Дата и время',
    enableSorting: true,
    cell: cell => {
      const value = cell.getValue() as number[];
      return formatDateByArray(value);
    }
  },
  {
    accessorKey: 'cardMasked',
    header: 'Номер карты',
    enableSorting: false
  },
  {
    accessorKey: 'status',
    header: 'Результат обработки',
    enableSorting: false,
    cell: cell => {
      const value = cell.getValue() as number;
      if (value === 0) return 'Успешная оплата';
      if (value === 1) return 'Ошибка оплаты';
      return 'Ожидание оплаты';
    }
  },
  {
    accessorKey: 'amount',
    header: 'Сумма операции',
    enableSorting: true,
    cell: cell => {
      const value = cell.getValue() as number;
      return separateThousands(value);
    }
  }
];
