import { AxiosResponse } from 'axios';
import { api } from 'config/axios';
import dayjs from 'dayjs';

export type PaymentsHistoryRequestParams = {
  pageIndex: number;
  startDate: string;
  endDate: string;
  orderNumber: string;
};

type PaymentType = {
  bnpl_id: number;
  order_id: number;
  amount: number;
  created_at: string;
  payment_count: number;
  buyer: string;
  manager: string;
  payment_date: string;
  success_payment_number: number;
  status: number;
  payment_sum: number;
};

export type PaymentsHistoryResponseType = {
  total_pages: number;
  total_elements: number;
  response_list: Array<Partial<PaymentType>>;
};

export const getPaymentsHistoryAsync = async (
  pageIndex: number,
  {
    startDate = dayjs(new Date()).format('YYYY-MM-DD'),
    endDate = dayjs(new Date()).format('YYYY-MM-DD'),
    orderNumber
  }: Partial<PaymentsHistoryRequestParams>
) => {
  // const URL = `https://testpaspay-guard.paspay.kz/bnpl/payment-history?page=${pageIndex}&startDate=${startDate}&endDate=${endDate}${
  const URL = `${
    process.env.REACT_APP_API_TESTGUARD
  }/bnpl/payment-history?page=${pageIndex}&startDate=${startDate}&endDate=${endDate}${
    orderNumber ? '&orderNumber=' + orderNumber : ''
  }`;

  try {
    const res: AxiosResponse<PaymentsHistoryResponseType> = await api.post(
      URL,
      { startDate, endDate, orderNumber }
    );

    return res;
  } catch (err) {
    console.log(err);
  }
};
