import { redirectDocument } from 'react-router-dom';

export const resetLoader = () => {
  const pathname = window.location.pathname;

  if (pathname === '/reset' || pathname === '/reset/') {
    return redirectDocument('/reset/forgot-password');
  }

  return null;
};
