import {
  OrdersDatePicker,
  OrdersFilter,
  OrdersFooter,
  OrdersList,
  OrdersQrCode,
  OrdersSearchFilter
} from 'modules/orders';

const OrdersPage = () => (
  <>
    <div className="d-flex justify-content-between mb-4">
      <OrdersSearchFilter />

      <div className="d-flex align-items-center">
        <OrdersQrCode />
        <OrdersDatePicker />
        <OrdersFilter />
      </div>
    </div>
    <OrdersList />
    <OrdersFooter />
  </>
);

export default OrdersPage;
