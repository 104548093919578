import { useEffect } from 'react';

const OAuthLogin = () => {
  const handleLogin = () => {
    // const redirectUri = 'http://localhost:5173/callback';
    const redirectUri = 'https://cabinet.paspay.kz/callback';
    const clientId = 'paspay-cabinet-web-client';

    window.location.href = `https://auth.paspay.kz/oauth2/authorize?client_id=${clientId}&redirect_uri=${redirectUri}&response_type=code&scope=read+write+trust+offline_access&state=eakfjslkaf&code_challenge=WEP3OhN2dfQIe82hZl82U9G6Q4KwhrjIY5ISUOVLkqs&code_challenge_method=S256`;
  };

  useEffect(() => {
    handleLogin();
  }, []);

  return null;
};

export default OAuthLogin;
