import { createSelectors } from 'config/zustand';
import { create } from 'zustand';
import { PaymentStatus } from '../types';
// import { SearchParamsKey } from 'enums';

// type InitialSearchParamsType = {
//   [SearchParamsKey.PaymentStatus]: PaymentStatus[];
// };

// const initialSearchParams: InitialSearchParamsType = {
//   [SearchParamsKey.PaymentStatus]: []
// };

type State = {
  status: string[];
  payment: string[];
  paymentStatus: StatusesBoolean;
};

type StatusesBoolean = {
  [status in PaymentStatus]: boolean;
};

const initialState: State = {
  status: [],
  payment: [],
  paymentStatus: {
    0: false,
    1: false,
    2: false,
    3: false,
    4: false,
    5: false,
    6: false,
    7: false
  }
};

type Action = {
  setPaymentStatus: (
    updater:
      | Partial<{ [status in PaymentStatus]: boolean }>
      | ((prev: { [status in PaymentStatus]: boolean }) => Partial<{
          [status in PaymentStatus]: boolean;
        }>)
  ) => void;
  setStatus: (status: State['status']) => void;
  setPayment: (payment: State['payment']) => void;
  reset: () => void;
};

// const { statusParams } = useGlobalStore;

const useOrdersFilterBase = create<State & Action>(set => ({
  status: initialState['status'],
  payment: initialState['payment'],
  paymentStatus: initialState['paymentStatus'],
  setStatus: status => set(() => ({ status: status })),
  setPaymentStatus: status => {
    // set({ status: statusParams });

    console.log(status);

    const entries = Object.values(PaymentStatus).map(item => [
      item
      // statusParams.includes(item)
    ]);

    const res = Object.fromEntries(entries) as {
      [status in PaymentStatus]: boolean;
    };

    console.log(res);
    return res;
  },
  setPayment: payment => set(() => ({ payment: payment })),
  reset: () => {
    set(initialState);
  }
}));

const useOrdersFilterStore = createSelectors(useOrdersFilterBase);

export default useOrdersFilterStore;
