import { useDebouncedCallback } from 'use-debounce';
import SearchBox from 'components/common/SearchBox';
import { useSearchParams } from 'react-router-dom';
import { SearchParamsKey } from 'enums';
import { usePaymentsHistorySearchStore } from './store';

const PaymentsHistorySearchFilter = () => {
  const [searchParams, setSearchParams] = useSearchParams({
    [SearchParamsKey.Search]: ''
  });
  const setSearch = usePaymentsHistorySearchStore.use.setSearch();

  const debounced = useDebouncedCallback(value => {
    setSearch(value);
    searchParams.set(SearchParamsKey.Search, value);
    setSearchParams(searchParams);
  }, 1000);

  return (
    <SearchBox
      defaultValue={searchParams.get(SearchParamsKey.Search) ?? ''}
      placeholder="Поиск по номеру заказа"
      onChange={e => debounced(e.target.value)}
    />
  );
};

export default PaymentsHistorySearchFilter;
