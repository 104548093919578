import { createSelectors } from 'config/zustand';
import { create } from 'zustand';

type State = {
  pageIndex: number;
};

const initialState: State = {
  pageIndex: 0
};

type Action = {
  setPageIndex: (pageIndex: State['pageIndex']) => void;
  reset: () => void;
};

const useOrdersPaginationBase = create<State & Action>(set => ({
  pageIndex: initialState['pageIndex'],
  setPageIndex: pageIndex => set(() => ({ pageIndex: pageIndex })),
  reset: () => set(initialState)
}));

const useOrdersPaginationStore = createSelectors(useOrdersPaginationBase);

export default useOrdersPaginationStore;
